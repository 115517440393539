.flt {
    width: 100%;
    float: left;
}
.event-main {
    min-height: calc(100vh - 435px);
}
.main-page {
    background-color: #f4e6d1;
}

.logsign-btton {
    display: flex;
    padding: 10px 0px;
}
.blog-main {
    background-color: #FFF7EB;
}

.event-img img{
    width: 100%;
}

.event-maindiv {
    padding: 3%;
}

.event-text {
    padding: 3% 0;
    font-size: 28px;
    font-weight: bold;
    color: #712527;
    line-height: 41px;
}

.blog-divdr hr{
    margin: 0;
}

.event-date {
    font-family: Spectral;
    font-size: 12px;
    font-weight: 600;
    color: #712527;
    line-height: 10px;
    margin: 5% 0;
}

.event-headtxt {
    font-family: Spectral;
    font-size: 17px;
    font-weight: 500;
    color: #1b1a19;
    margin: 1% 0;
}

.event-contnttxt {
    font-family: Spectral;
    font-size: 14px;
    font-weight: 400;
    color: #7e7e7e;
    margin: 2% 0;
    
}

.event-main {
    margin: 45px 0px;
    background-color: #FFF6E8;
}

.event-maindiv {
    margin-bottom: 2%;
    background-color: #fefefe;
    box-shadow: 5px 4px 3px 1px rgb(12 18 28 / 12%);

}
@media (max-width: 768px) {
   
}