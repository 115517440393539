.pagination {
  justify-content: flex-end;
  margin-top: 40px !important;
}
.pagination li {
  margin-left: 10px;
}
.pagination li a {
  border-radius: 5%;
  width: 25px;
  height: 25px;
  line-height: 25px;
  display: block;
  border: 1px solid rgb(0, 0, 0);
  text-align: center;
  font-size: 11px;
}
.displayopacity {
    pointer-events: none;
    opacity: 0.5;
}


.pagination li:first-child a,
.pagination li:last-child a {
  width: auto;
  height: auto;
  border: none;
  text-transform: uppercase;
}
.pagination li.active a {
  background-color: transparent;
  color: #c98f2c !important;
}
.image-sec.image,
.image-sec.audio,
.image-sec.video {
  width: 100%;
}
.list-main-sec {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3% 5%;
  background-color: rgb(255, 247, 235);
}
.cartpg.flt {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  text-align: center;
  background-color: rgb(255 255 255 / 90%);
  z-index: 9;
  display: flex;
  align-items: center;
  width: 100%;
  align-content: center;
  justify-content: center;
}
.no-data {
  text-align: center;
  padding: 2% 0;
  width: 100%;
}
.audio {
  width: 100%;
}
.modal-dialog {
  width: 50% !important;
}
.bMgaAx div:first-child {
  width: 100%;
}
.tbl-cell-data {
  width: 100% !important;
}
img.image-sec {
  cursor: pointer;
}
.image-sec-list {
  width: 100%;
  max-height: 90px;
}
.table-image-cart {
  width: 100%;
  max-height: 500px !important;
}
.datevisible{
  visibility: hidden;
}
.close-modal-sec-table {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 9999999;
}
.dFsTSm{
  overflow: initial !important;
  white-space: normal !important;
}
.tbl_data {
    /* display: none; */
    visibility: hidden;
}

#enquiryModal .modal-body {
  background-color: rgba(244, 230, 209, 1);
}
.pop-up-close-table {
  border: none;
  background: black;
  box-shadow: none;
  font-size: 28px;
  padding: 0;
  color: wheat;
  border-radius: 20px;
  width: 28px;
  height: 28px;
  line-height: 0;
}
.table-pop-up-img,
.table-pop-up-aud {
  border-radius: 20px;
  padding: 1%;
}
.table-pop-up-aud {
  display: flex;
  flex-direction: column;
}
.table-pop-up-aud audio {
  width: 100%;
}
.reset-btn-filter{
    width: 15%;
    box-shadow: none;
    font-size: 15px;
    background-color: transparent;
    border: 1px solid rgba(201, 143, 44, 0.38);
}
.reset-btn-filter:hover{
    background-color: rgba(113, 37, 39, 0.6);;
    color: white;
}
.list-filter-sec-div{
    display: flex;
    flex-direction: row;
    width: 70%;
}
.tbl-cell-data.img{
    cursor: pointer;
}

@media (max-width: 768px) {
  .table-pop-up-aud1{
    display: block;
  }
  .table-image-cart {
    max-height: 100% !important;
  }
  .eUeqdG {
    white-space: inherit !important;
    text-overflow: inherit;
    overflow: initial !important;
  }
  .filter-search {
    margin-top: 9px;
    height: 45px;
  }
  .list-filter-sec-div{
      padding: 0 !important;
  }
  .reset-btn-filter{
      margin-top: 9px;
      width: 35%;
  }
  .tbl-cell-data:hover .tbl_data {
    visibility: visible;
    background-color: black;
    color: white;
    white-space: initial !important;
    overflow: visible !important;
    text-overflow: ellipsis;
    width: fit-content  !important;
}

}
