input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.invalid-mob {
  display: block !important;
}
.login_container {
  width: 100%;
  padding: 2% 15%;
  background-color: #FFF6E8;
}
.image-sec-right {
  width: 100%;
  background-image: url("../../../assets/images/bg-image.png");
}
.login_box {
  display: flex;
  flex-direction: row;
}
.loginimg{
  text-align: center;
}
.login_cont {
  display: flex;
  flex-direction: column;
  padding: 1% 5%;
  width: 100%;
  background-color: white;
}
.sec-header {
  font-family: MuktaMalarMedium;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: 0;
  color: #712527;
  text-align: center;
  text-transform: uppercase;
}
.input-sec {
 border-radius: 25px;
    border-color: #712527;
    padding: 19px 30px;
    width: 100%;
    border: 1px solid rgba(96, 31, 33, 1);
    font-family: MuktaMalarMedium;
    font-size: 12px;
    letter-spacing: 1px;
    outline: none;
}
.form-group.flt {
  margin: 2.5% 0;
}
.login_btn {
  padding: 15px;
  background-color: #712527;
  border-radius: 25px;
  border-color: #712527;
  border-width: 1.5px;
  font-family: MuktaMalarNormal;
  box-shadow: none;
  color: white;
  margin: 12px 0px;
}
.input-sec::placeholder {
  color: #c98f2c;
  font-family: MuktaMalarMedium;
  text-transform: uppercase;
}
.forgot-pass-sec {
  display: flex;
  margin: 0 0 2% 0;
  justify-content: flex-end;
}
.forgot-password {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  font-family: MuktaMalarMedium;
  color: #c98f2c;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.reg-sec {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 7% auto;
}
.reg-sec-content {
  color: #712527;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.invalid-feedback {
  font-family: MuktaMalarMedium;
  font-size: 12px;
  margin: auto 2%;
}
.right-sec-header {
  color: #c98f2c;
  font-family: AguafinaScript;
  font-size: 36px;
}
.right-sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  align-self: center;
  justify-content: center;
  height: 100%;
  padding: 0 5%;
}
.right-sec-desc{
  font-family: Spectral;
  color: rgba(244, 230, 209, 1);
  font-size: 16px;
  text-align: center;
}
.reg-sec-content a{
  color: #712527;
  text-decoration: none;
}
.reg-sec-content a:hover{
  cursor: pointer;
}
.login_btn:hover{
  cursor: pointer;
}

@media (max-width: 768px) {
  .login_box{
    flex-direction:column-reverse;
  }
  .right-sec{
    padding: 5%;
  }
  .login_container{
    padding: 4% 5%;
  }
}