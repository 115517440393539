.flt {
  float: left;
  width: 100%;
}

.filler {
  flex-grow: 1;
}

.data_text {
  font-family: 'Mukta Malar';
  font-size: 31px;
  font-weight: 500;
  color: rgba(113, 37, 39, 1);
  margin: 36px 0px;
}

.main-div {
  float: left;
  width: 100%;
}

.image-div-list {
  width: 100%;
  /* padding: 1%; */
  border-radius: 10px;
  border: 1px solid rgba(255, 233, 194, 1);
  margin-top: 30px;
}

.image-sec-list-page {
  width: 100%;
  height: 100%;
  min-height: 198px;
}

.image-data-sec {
  width: 100%;
  padding: 15px 10px;

  /* flex-direction: row; */
  /* border-color: rgba(12, 18, 28, 0.12); */
  /* border-style: solid; */
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-left-radius: 8px;
  background-color: #FFE9C2;
  border-bottom-right-radius: 8px;
}

.image-data-name-sec {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}

.image-data-name {
  font-weight: 400;
  font-size: 18px;
  color: rgb(113 37 39);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 75%;
}

.image-data-amount {
  font-size: 15px;
  margin-bottom: 10px;
  color: #000000;
}

.image-data-count-sec {
  width: 25%;
  text-align: right;
}

.display_btn {
  width: 50%;
}

.display_price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.image-data-count {
  font-size: 15px;
  color: rgb(12, 12, 12);
  margin: 0px !important;
}

.detail-sec-item img {
  border-radius: 10px 10px 0px 0px;
  height: 215px;
}

.detail-sec-item.audio img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}

.css-26l3qy-menu {
  background-color: rgba(244, 230, 209, 1) !important;
}

.modal-dialog {
  max-width: 800px !important;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border-bottom: 2px solid rgb(234 199 137);
}

.eUeqdG {

  white-space: unset !important;

}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 1.5% 3%;
  transition: 0.3s;
  font-size: 18px;
  font-family: MuktaMalarMedium;
  color: rgb(67, 67, 67);
}

/* Change background color of buttons on hover */
.tab button:hover {
  color: rgba(201, 143, 44, 1);
}

/* Create an active/current tablink class */
.tab button.active {
  color: rgb(113 37 39);
  border-bottom: 2px solid rgb(113 37 39);
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.search_main .col-sm-4 {
  padding-right: 0px;
}

.search_left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search_left button {
  font-size: 14px;
  font-weight: 400;
  background: #FFF6E8;
  border: 1px solid #712527;
  border-radius: 3px;
  padding: 8px 10px;
  margin-right: 5px;
  width: 33.33%;
}

/* .search_left button:hover {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(113, 37, 39, 1);
  border: 1px solid rgba(255, 197, 99, 1);
} */
.imagebutton {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(113, 37, 39, 1) !important;
  border: 1px solid rgba(255, 197, 99, 1);
}

.search_main .col-sm-8 {
  padding-left: 0px;
}

.search_right {
  position: relative;
}

.search_right input {
  font-size: 15px;
  font-weight: 400;
  background: #FFF7EB;
  border: 1px solid #EAC789;
  box-shadow: 0px 2px 3px rgba(12, 18, 28, 0.15);
  border-radius: 7px;
  padding: 10px 20px;
  outline: none;
  width: 100%;
}

.search_right input::placeholder {
  color: rgba(138, 138, 138, 0.87);
}

.src_icon {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: rgba(113, 37, 39, 1);
  border-radius: 0px 6px 6px 0px;
  padding: 10px 20px;
}

.src_icon i {
  color: rgba(255, 255, 255, 1);
}



@media (max-width: 768px) {
  .list-filter-sec .list-custom-class__control {
    padding: 2% 2%;
  }

  .main-div {
    flex-direction: column;
  }

  .image-div-list {
    width: 100%;
    max-height: 100%;
  }

  div [role="gridcell"] {
    width: 120px !important;
  }

  .list-filter-sec {
    flex-direction: column !important;
  }

  .custom-drop-down {
    width: 100% !important;
  }

  .list-filter-sec select,
  .custom-drop-down:nth-child(2) {
    padding: 1% 2% !important;
  }

  .tab button {
    padding: 2% 4%;
  }

  .tab {
    margin: 2% 0;
  }

  #enquiryModal {
    justify-content: center;
  }

  .content-div {
    width: 100% !important;
    margin-top: 3%;
    margin-left: 0 !important;
    text-align: center;
  }

  .image-div {
    width: 100% !important;
  }

  .modal-dialog {
    width: 95% !important;
  }
}

.pagination {
  justify-content: flex-end;
  margin-top: 40px !important;
}

.pagination li {
  margin-left: 10px;
}

.pagination li a {
  border-radius: 10%;
  width: 30px;
  height: 30px;
  line-height: 28px;
  display: block;
  border: 1px solid #a39f9f;
  text-align: center;
  font-size: 12px;
  color: #717171;
}

.pagination li:first-child a,
.pagination li:last-child a {
  width: auto;
  height: auto;
  border: none;
  text-transform: uppercase;
}

.pagination li.active a {
  background-color: transparent;
  color: #712527 !important;
  border: 1px solid #712527;
}

.rdt_TableHead,
.rdt_TableHead .rdt_TableHeadRow,
.rdt_TableBody .rdt_TableRow {
  background-color: #FFF7EB;
}

.rdt_TableHeadRow div {
  font-family: MuktaMalarMedium;
  color: #535151;
  font-size: 14px;
}

.rdt_TableBody .rdt_TableRow {
  padding: 2% 0;
  border-color: rgba(201, 143, 44, 0.38) !important;
}

.rdt_TableRow div div {
  /* display: flex; */
  align-items: center;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rdt_TableRow div div span {
  padding-left: 2%;
}

.rm-cart-button1 {
  border: 1px solid rgba(148, 148, 148, 1);
  border-radius: 58px;
  background: transparent;
  padding: 5px 20px;
  font-family: 'Mukta Malar';
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.cart button {
  width: 100px;
}

.add-to-cart {
  width: 100%;
  border: 1px solid rgb(113, 37, 39);
  border-radius: 58px;
  background-color: rgb(255, 255, 255);
  padding: 5px 28px;
  font-family: 'Mukta Malar';
  font-size: 13px;
  font-weight: 400;
  color: rgb(113, 37, 39);
}

.add-to-cart.image {
  padding: 10%;
  width: 100%;
}

.add-to-cart.video {
  padding: 10%;
  width: 100%;
}

.add-to-cart.audio {
  padding: 10%;
  width: 100% !important;
}

.add-to-cart:hover {
  background-color: rgba(113, 37, 39, 0.6);
  color: white;
  /* width: 100% !important; */
}

.list-filter-sec {
  display: flex;
  flex-direction: row;
  /* box-shadow: 0 2px 0px 0 rgba(12, 18, 28, 0.12); */
  margin-bottom: 10px;
}

.custom-drop-down {
  width: 30%;
}

.custom-drop-down .list-custom-class__option {
  background-color: transparent;
  padding: 0;
}

.list-custom-class__option .option-sec:hover {
  background-color: rgb(189, 189, 189);
  color: white;
  cursor: pointer;
}

.option-sec {
  display: flex;
  align-items: center;
  width: 100%;
}

.option-sec img {
  margin-right: 5%;
}

.css-1okebmr-indicatorSeparator {
  background-color: transparent !important;
}

.list-filter-sec select,
.list-filter-sec .list-custom-class__control {
  padding: 2.8% 2%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-color: rgba(201, 143, 44, 0.38);
  color: #0c121c;
  font-family: MuktaMalarBold;
  border-right-color: transparent;
  background-color: transparent;
  outline: none;
}

.list-filter-sec input,
.list-filter-sec input:active,
.list-filter-sec input:focus {
  width: 100%;
  border: 1px solid rgba(201, 143, 44, 0.38);
  border-radius: 5px;
  font-family: MuktaMalarNormal;
  background-color: transparent;
  border-width: 1px;
  padding: 1% 2%;
  outline: none;
}

.table-sec {
  margin: 2% 0;
}

div [role="gridcell"] {
  width: 100px !important;
}

div [role="gridcell"]:last-child div {
  width: 100%;
}

.table-list div [role="gridcell"]:last-child div {
  width: 50%;
}

.cartpg.flt {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  text-align: center;
  background-color: rgb(255 255 255 / 90%);
  z-index: 9;
  display: flex;
  align-items: center;
  width: 100%;
  align-content: center;
  justify-content: center;
}

.no-data {
  text-align: center;
  padding: 2% 0;
  width: 100%;
}

.detail-sec-item.image,
.detail-sec-item.video {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* max-height: 200px; */
  /* padding: 10px 0px; */
  position: relative;
}

.detail-sec-item.video {
  /* display: block; */
}

.detail-sec-item.audio {
  /* display: flex; */
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* padding: 10px; */
  position: relative;
}

.audio-img-sec {
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.audio-img-sec img {
  width: 100%;
}

.vw-dtl-btn {
  display: none;
  position: absolute;
  color: #712527;
  font-family: MuktaMalarNormal;
  font-weight: 400;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #c98f2c;
  border-radius: 20px;
  padding: .2% 3%;
  text-transform: capitalize;
  /* z-index: 999; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vw-dtl-btns {
  z-index: 1;
}

.vw-dtl-btn.show {
  display: block;
  opacity: 1;
  z-index: 1 !important;
}

.detail-sec-item.show img {
  background-color: rgb(255, 255, 255) !important;
  z-index: -1;
  opacity: 0.5;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  /* background: rgba(0, 255, 255, 0.5); */
  /* border-radius: 8px 8px 0px 0px; */
}

img.image-sec {
  width: 100%;
}

.detail-sec-item1.show video {
  background-color: rgb(255, 255, 255) !important;
  z-index: -1;
  opacity: 0.5;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  /* background: rgba(0, 255, 255, 0.5); */
  /* border-radius: 8px 8px 0px 0px; */
}

.detail-sec-item1 video {
  width: 100%;
}

.detail-sec-item1.show .overlay {
  position: relative;
  display: inline-block;
  /* background: rgba(113, 37, 39, 0.69); */
}

.video_main {
  z-index: -1;
}

.overlay {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.overlay::before {
  border-radius: 10px 10px 0px 0px;
}

.detail-sec-item.show .overlay {
  position: relative;
  display: inline-block;
  /* background: rgba(113, 37, 39, 0.69); */
}

.detail-sec-item.show .overlay>img {
  vertical-align: middle;
}

.detail-sec-item.show .overlay::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: 0.5s ease;
}

.detail-sec-item.show .overlay:hover::before {
  opacity: 0.5;
}

.modalPop {
  position: fixed;
  display: flex;
  width: 100%;
  z-index: 999999;
  height: 100%;
  background-color: transparent;
  align-content: center;
  align-items: center;
}

.list-outer-sec.inactive .menu-main,
.list-outer-sec.inactive.footer-main,
.list-main-sec.inactive {
  /* opacity: 0.6; */
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.modal-detail-head {
  color: #712527;
  font-size: 20px;
}

.list-main-sec {
  opacity: 1;
}

.pop-up-details-div {
  display: flex;
  height: 100%;
  padding: 3%;
}

.pop-up-close {
  border: none;
  background: transparent;
  box-shadow: none;
  font-size: 30px;
  padding: 0;
}

.close-modal-sec {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(201, 143, 44, 1);
  align-items: center;
  padding: 1% 3%;
}

#enquiryModal {
  background-color: rgba(0, 0, 0, 0.4);
}

#enquiryModal .modal-body {
  background-color: rgba(244, 230, 209, 1);
}

.css-1uccc91-singleValue {
  width: 100%;
}

.list-custom-class__option .option-sec {
  text-align: center;
  align-items: center;
  background-color: #f4e6d1;
  color: black;
  padding: 4%;
}

/* .detail-sec-item video {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  border-radius: 10px 10px 0px 0px;
  max-height: 210px;
} */

.detail-sec-item video {
  width: 100%;
  height: 210px;
  border-radius: 10px 10px 0px 0px;
}

.modal-dialog,
.modal-content {
  border-radius: 10px;
  border: 1px solid rgba(244, 230, 209, 1) !important;
}

.modal-body {
  padding: 0 !important;
}

.content-div {
  width: 60%;
  background-color: white !important;
  margin-left: 2%;
  border-radius: 8px;
  padding: 2%;
}

.image-div img {
  min-height: 230px;

  width: 100%;
}

.image-div {
  width: 40%;
  height: 100%;
  padding: 0.7% !important;
  align-items: center;
  display: block;
  background-color: white;
  border-radius: 8px;
  box-shadow: 3px 3px 3px 3px rgb(12 18 28 / 13%);
}

.hover_data {
  display: none;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  background-color: rgb(255 255 255);
  padding: 0px 10px;
  border-radius: 4px;
  font-size: 10px;
  box-shadow: 0px 0px 5px rgb(159 159 159 / 60%);
}

.image-data-name-sec:hover .hover_data {
  display: block !important;
}

.audio-img-sec-popup {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  text-align: center;
}

.audio-img-sec-popup audio {
  width: 100%;
}

.item-title {
  font-size: 18px !important;
}

.item-date,
.item-price {
  font-size: 16px !important;
}

.image-sec-list {
  border-radius: 8px !important;
  margin-right: 0 !important;
}

.footer-main.hide {
  background-color: rgba(0, 0, 0, 0.5);
}

.detail-sec-item audio {
  width: 100%;
  margin-top: 2%;
  padding: 0 1%;
}

.button_image button {
  background: #FFF6E8;
  border: 1px solid #712527;
  border-radius: 3px;
  font-family: 'Mukta Malar', sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #2A2E34;
  padding: 10px 16px;
  margin-right: 7px;

}

.button_image button img {
  margin-right: 6px;
}

.detail-sec-item1.video video {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.detail-sec-item1.video {
  position: relative;
  height: 210px;
}

.vw-dtl-btn1.vw-dtl-btns1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.vw-dtl-btn1.vw-dtl-btns1 {
  display: none;
  position: absolute;
  color: #712527;
  font-family: MuktaMalarNormal;
  font-weight: 400;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #c98f2c;
  border-radius: 20px;
  padding: .2% 3%;
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

/* 
.detail-sec-item1.video::before:hover {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 210px;
  background-color: rgb(255, 247, 235);
  border-radius: 10px 10px 0px 0px;
  z-index: 1;
} */

/* .detail-sec-item1.video:hover {
  background-color: rgb(255 0 0);
  border-radius: 10px 10px 0px 0px;
opacity: 0.5;
} */


.detail-sec-item1.video:hover img.image-sec {
  cursor: pointer;
  position: relative;
  /* z-index: -1; */
  opacity: 0.5;
}

.detail-sec-item1.video:hover video.image-sec {
  cursor: pointer;
  position: relative;
  z-index: -1;
}

.detail-sec-item1.video:hover .vw-dtl-btn1.vw-dtl-btns1 {
  display: block;
  z-index: 1 !important;
}



@media (min-width: 320px) and (max-width: 479px) {
  .video_mobile {
    width: 100%;
  }
  .image-div-list audio {
    width: 100%;
  }

  .pop-up-details-div {
    display: block;
    height: 100%;
  }

  .css-2b097c-container {
    position: relative;
    box-sizing: border-box;
    /* margin: 14px 0px; */
  }

  .pagination li a {
    /* width: 0px; */
  }

  .pagination {
    justify-content: center;
  }

  .detail-sec-item {
    display: block;
    max-height: none;
  }

  .display_price {
    display: block;
  }

  .image-data-name {
    width: 100%;
    white-space: initial;
    overflow: visible;
    text-overflow: initial
  }

  .image-data-count-sec {
    width: 100%;
    text-align: left;
    margin: 5px 0px;
  }
}

.audio_main {
  z-index: 1;
  text-align: center;
}



@media (min-width: 320px) and (max-width: 479px) {

  .image-sec {
    width: 100%;
  }

  .add-to-cart {
    padding: 0px;
  }

  .rm-cart-button {
    padding: 0px;
  }

  .tab button {
    padding: 2%;
  }

  video {
    max-height: 200px;
    /* z-index: -1; */
  }

  .image-div img {
    min-height: 230px;
    max-height: 250px;
    width: 100%;
  }

  .audio_main {
    z-index: -1;
  }

  /* 
  .detail-sec-item.video video {
    height: 100%;
  }

    .detail-sec-item.video video {
    z-index: 1;
}

  .detail-sec-item.video.show video {
    z-index: 1;
}

.vw-dtl-btn.show {
    display: block;
    opacity: 1;
    z-index: 1 !important;
} */

}

@media (max-width: 767px) {
  .search_main .col-sm-4 {
    padding-right: 10px;
  }

  .src_icon {
    top: 10px;
  }


  .search_main .col-sm-8 {
    padding-left: 10px;
  }

  .search_left button:last-child {
    margin-right: 0px;
  }

  .tab button {
    font-size: 15px;
  }

  .search_left button {
    font-size: 12px;
  }

  .search_right input {
    font-size: 12px;
    padding: 10px 15px;
  }

  /* .detail-sec-item.video.show .detail-sec-item.video video {
  z-index: 1;
} */

  .detail-sec-item.video video {
    /* z-index: 1; */
    height: 100% !important;
  }

}


@media (min-width: 480px) and (max-width: 639px) {
  .display_price {
    display: block;
  }

  .image-data-name {
    width: 100%;
    white-space: initial;
    overflow: visible;
    text-overflow: initial
  }

  .image-data-count-sec {
    width: 100%;
    text-align: left;
    margin: 5px 0px;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .display_price {
    display: block;
  }

  .image-data-name {
    font-size: 15px;
    width: 100%;
    white-space: initial;
    overflow: visible;
    text-overflow: initial;
  }

  .image-data-count-sec {
    width: 100%;
    text-align: left;
    margin: 5px 0px;
  }

  .image-data-count {
    font-size: 13px;
  }

  .image-data-amount {
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .src_icon {
    top: 10px;
  }

  .search_main .col-sm-4 {
    padding-right: 15px;
    width: 100%;
  }

  .search_main .col-sm-8 {
    padding-left: 10px;
    width: 100%;
  }

  .search_left button:last-child {
    margin-right: 0px;
  }

  .display_price {
    display: block;
  }
  .image-data-name {
    font-size: 15px;
    width: 100%;
    white-space: initial;
    overflow: visible;
    text-overflow: initial;
  }
  .image-data-count-sec {
    width: 100%;
    text-align: left;
    margin: 5px 0px;
  }
  .image-data-count {
    font-size: 13px;
  }
  .image-data-amount {
    font-size: 13px;
  }
}

.data_display {
  padding: 18px 0px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .display_price {
    display: block;
  }
  .image-data-name {
    font-size: 15px;
    width: 100%;
    white-space: initial;
    overflow: visible;
    text-overflow: initial;
  }
  .image-data-count-sec {
    width: 100%;
    text-align: left;
    margin: 5px 0px;
  }
  .image-data-count {
    font-size: 13px;
  }
  .image-data-amount {
    font-size: 13px;
  }
}