.myaccount_main{
    padding: 70px;
        min-height: calc(100vh - 208px);
}
.accountList{
    padding: 8%;
}
.accountList li {
    list-style-type: none;
    float: left;
    width: 100%;
    margin-bottom: 2px;
    background-color: rgba(255, 245, 231, 1);

}
.accountList li a.active {
    background-color: rgba(241, 187, 95, 1);
    font-family: 'Mukta Malar';
    font-size: 12px;
    font-weight: 400;
    color: rgba(113, 37, 39, 1);
}
.myacc{
    padding:16px;
}
.accountList li a {
    letter-spacing: 0;
    line-height: 17px;
    text-decoration: none;
    padding: 16px;
    float: left;
    width: 100%;
    background-color: #f7f7f7;
    font-family: 'Mukta Malar';
font-size: 12px;
font-weight: 400;
color: rgba(113, 37, 39, 1);
}
.accountrightmenu{
    font-family: 'Mukta Malar',sans-serif;
font-size: 20px;
font-weight: 400;
color: rgba(0, 0, 0, 1);
line-height: 15px;
}
.acc_detail {
    background-color: rgba(255, 245, 231, 1);
    border-radius: 6px;
    padding: 14px;
    font-family: 'Mukta Malar',sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
}
.acc_holder {
    padding: 18px 0px;
}
.accsub_detail{
    background-color: rgba(250, 237, 218, 1);
}
.subdetail li{
    list-style: none;
}
.subdetail li a{
    font-family: 'Mukta Malar';
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);

}
ul.subdetail li {
    padding: 3px 0px;
}
li.subdtl a {
    font-family: 'Mukta Malar';
    font-size: 20px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
}
.user_detail tbody tr:nth-child(odd){
    background-color: rgba(250, 237, 218, 1);
}
.user_detail th {
    
    font-family: 'Mukta Malar';
font-size: 12px;
font-weight: 400;
color: rgba(113, 37, 39, 1);
background-color: rgba(250, 237, 218, 1);
padding: 6px 0px;
text-align: center;
width: 106px;
}
/* .user_detail tbody{

} */
.user_detail tbody td{
    text-align: center;
        font-family: 'Mukta Malar',sans-serif;
font-size: 12px;
font-weight: 400;
color: rgba(0, 0, 0, 1);
padding: 10px 0px;
}
.main_table {
    padding: 24px 0px;
  
}
.my_order{
    font-family: 'Mukta Malar';
    font-size: 20px;
    font-weight: 400;
    color: rgba(113, 37, 39, 1);
    padding: 10px 24px;
    background-color: rgba(255, 245, 231, 1);
}
@media (max-width: 767px) {
  .myaccount_main{
        padding: 20px;
    }
    .acc_holder {
    padding: 18px 10px;
}
.my_order{
        font-size: 16px;

    padding: 16px 14px;

}
.user_detail th{
    padding: 10px 10px;
}
.map_box{
        padding: 35px 122px;
}
.container_header_main{
    padding: 0px !important;
}
}
@media (min-width: 320px) and (max-width: 479px) {
    .accountList {
        padding: 3%;
    }
}
@media (min-width: 480px) and (max-width: 639px) {
    .map_box {
        padding: 35px 12px;
    }
    .accountList {
        padding: 3%;
    }
}
@media (min-width: 640px) and (max-width: 767px) {
    .myacc {
        padding: 5px;
    }
    .myacc img {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .myacc {
        padding: 5px;
    }
    .myacc img {
        width: 100%;
    }
}
@media  (min-width: 768px) and (max-width: 1366px) {
  .map_box{
      padding: 35px 106px;
  }
  .cont_box{
      padding: 0px 4px;
  }
}

