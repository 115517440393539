.image-div-cart .image-sec-list {
  width: 100%;
  margin-right: 2%;
  max-height: 210px;

}

.get-cart-div {
  padding: 8%;
}

.cart-sectn {
  display: flex;
  background-color: rgba(234, 220, 199, 1);
  width: 100%;
  flex-direction: column;
  padding: 2% 4%;
  border-radius: 8px;
  min-height: calc(100vh - 208px);
}

.main-div-cart {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 1% 0;
  /* justify-content: space-between; */

}

.image-div-cart {
  align-items: center;
  width: 30% !important;
  display: flex;
  background-color: white;
  border-radius: 8px;
  padding: 0.5%;
  display: flex;
  flex-direction: column;
  box-shadow: 3px 3px 3px 3px rgb(12 18 28 / 13%);
}

.image-div-cart video {
  width: 100%;
}

.content-div-cart {
  width: 50%;
  background-color: rgba(244, 230, 209, 1);
  margin-left: 2%;
  border-radius: 8px;
  padding: 2%;
  /* box-shadow: 3px 3px 3px 3px rgb(12 18 28 / 10%); */
}

.item-title {
  font-family: MuktaMalarMedium;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0.5%;
}

.item-title span {
  font-size: 18px;
  text-transform: capitalize;
}

.item-price {
  font-family: MuktaMalarMedium;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0.5%;
}

.item-date {
  font-family: MuktaMalarMedium;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0.5%;
}

.button-sec {
  width: 100%;
  padding: 2px 38px;
  text-align: right;
}

.checkout-button {
  background-color: rgba(241, 187, 95, 1);
  border-color: rgba(113, 37, 39, 1);
  font-family: MuktaMalarBold;
  border-width: 0.5px;
  padding: 1% 1.5%;
  border-radius: 20px;
  color: white;
  box-shadow: none;
  font-size: 16px;
  border-style: solid;
}

.checkout-button:hover {
  background-color: rgba(113, 37, 39, 1);
}

.proceed_checkout {
  text-align: right;
  padding: 24px 235px;
}

.rm-cart-button {
  background-color: transparent;
  border-color: rgba(118, 118, 118, 1);
  font-family: MuktaMalarBold;
  border-width: 0.5px;
  padding: 5px 7px;
  border-radius: 20px;
  color: rgba(118, 118, 118, 1);
  box-shadow: none;
  font-size: 16px;
  border-style: solid;
  width: 100%;
}

.rm-cart-buttons {
  background-color: transparent;
  border-color: rgba(118, 118, 118, 1);
  font-family: MuktaMalarBold;
  border-width: 0.5px;
  padding: 5px 7px;
  border-radius: 20px;
  color: rgba(118, 118, 118, 1);
  box-shadow: none;
  font-size: 16px;
  border-style: solid;
}

.rm-cart-button:hover {
  background-color: rgba(234, 220, 199, 1);
}

@media (max-width: 767px) {
  .space_around {
    padding: 10px 16px;
  }

  .main-div-cart {
    flex-direction: column;
  }

  .content-div-cart,
  .image-div-cart,
  .button-sec {
    width: 100%;
    display: flex;
    flex-direction: column;

  }

  .button-sec {
    padding: 2%;

  }

  .content-div-cart {
    margin: 0;
    text-align: center;
  }

  .checkout-button {
    width: 100%;
    margin: 0px;
  }

  .proceed_checkout {
    padding: 24px;
    text-align: center;
  }

  .image-sec-list-cart {
    max-height: 100%;
  }
}

@media (max-width: 767px) {

  .image-div-cart {
    /* min-height: 100px;
    max-height: 100%; */
    margin-bottom: 2%;
    padding: 3%;
    width: 100% !important;
  }

  .item-title {
    font-size: 14px !important;
    margin-bottom: 5px !important;
  }

  .item-title span {
    font-size: 14px;
  }

  .item-date,
  .item-price {
    font-size: 12px;
  }

  .cart-sectn .main-div-cart {
    margin-bottom: 15px;
  }

}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .button-sec {
    width: 90%;
  }

  .content-div-cart {
    width: 68%;
  }
}

.audio {
  width: 100%;
  /* height: 29%; */
}

.table-image {
  width: 100%;
  max-height: 500px;
}

.empty_main.flt {
  text-align: center;
}

.empty_section {
  font-family: 'Mukta Malar', sans-serif;
  font-size: 31px;
  font-weight: 500;
  color: rgba(113, 37, 39, 1);
  text-align: center;
}

.empty_sec {
  font-family: 'Mukta Malar', sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: rgba(201, 143, 44, 1);
  text-align: center;
}

.empty_button {
  text-align: center;
  margin-top: 25px;
}

.empty_button button {
  background-color: rgba(241, 187, 95, 1);
  border: 1px solid rgba(113, 37, 39, 1);
  border-radius: 30px;
  padding: 5px 61px;
}


@media (min-width: 320px) and (max-width: 479px) {
  .video_main1 {
    z-index: 1 !important;
  }

  .proceed_checkout {
    padding: 24px 15px;
  }

}

.content-div-cart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


@media (min-width: 768px) and (max-width: 991px) {
  .proceed_checkout {
    text-align: right;
    padding: 24px 110px;
  }

  .proceed_checkout .checkout-button {
    padding: 5px 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .proceed_checkout {
    text-align: right;
    padding: 24px 0px;
  }
}

@media (min-width: 1200px) and (max-width: 1365px) {
  .proceed_checkout {
    text-align: right;
    padding: 24px 0px;
  }
}