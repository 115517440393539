.flt {
  float: left;
  width: 100%;
}

.displayproperty {
  visibility: hidden;
}

.cart-count {
  font-size: 10px;
  background-color: white;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #c98f2c;
}

.cart-sec {
  display: flex;
}

.chckot-maintxt-orders {
  background-color: #eadcc7;
  padding: 2%;
}

.chckot-maintxt {
  display: flex;
  background-color: #eadcc7;
  padding: 2%;
}

.chcekout-maindiv {
  padding: 42px 0px;
}

.chkot-disply {
  display: flex;
  margin: 27px 0px;
}

.checkout-div {
  border-right: 1px solid #dc8b00;
  background-color: #fffaf2;
  padding: 2%;
  border-radius: 10px 0px 0px 10px;
}

.checkout-secdiv {
  background-color: #fffaf2;
  padding: 2%;
  border-radius: 0px 10px 10px 0px;
}

.chckot {
  font-family: MuktaMalarMedium;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
}

.chekout-account {
  font-family: MuktaMalarMedium;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  padding: 0px 0px 28px 0px;
}

.left-div {
  float: left;
  width: 50%;
  border: 1px solid rgba(242, 204, 156, 1);
  background-color: rgba(255, 255, 255, 1);
}

.right-div {
  width: 50%;
  padding: 2% 5%;
}

.sign-inbuttn {
  padding-bottom: 24px;
}

.sign-inbuttn a {
  font-family: MuktaMalarMedium;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 30px;
  padding: 1% 5%;
  background-color: #f1bb5f;
  border: 1px solid #dc8b00;
  text-decoration: none;
}

.sign-inbuttn a:hover {
  color: #712527;
}

.order-detail-main-sec {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgba(255, 250, 242, 1);
  padding: 2%;
  border-radius: 8px;
  margin-top: 4%;
}

.sub-sec-div {
  display: flex;
}

.order-haed {
  color: rgba(113, 37, 39, 1);
  font-family: MuktaMalarMedium;
  font-size: 16px;
  font-weight: 500;
  padding: 4%;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(242, 204, 156, 1);
  flex-direction: column;
  display: flex;
  margin-bottom: 1%;
}

.order-haed-billing {
  color: rgba(113, 37, 39, 1);
  font-family: MuktaMalarMedium;
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  flex-direction: column;
  /* display: flex; */
  margin-bottom: 1%;
}

.order-details-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 9px;
}

.order-details-image {
  width: 30%;
  /* padding: 1%; */
  /* box-shadow: 2px 1px 5px 2px rgb(12 18 28 / 13%); */
  /* display: flex; */
  flex-direction: column;
  max-height: 100%;
}

.order-details-image.audios {
  width: auto;
}

.order-details-image.videos {
  width: 60%;
}

.order-details-image video {
  width: 100%;
  height: 100%;
}

.order-details-image audio {
  height: 30px;
  margin-top: 10px;
}

.order-details-data {
  width: 70%;
  padding: 0 4%;
}

.order-details-divider {
  /* padding: 2% 4%;
  width: 100%; */
}

.order-details-divider hr,
.order-calc-tot hr {
  margin: 0;
  position: relative;
  height: 1px !important;
  border: none !important;
  color: rgba(242, 204, 156, 1);
}

.order-details-prod-name {
  color: #000000;
  font-family: MuktaMalarMedium;
  font-size: 16px;
  font-weight: 500;
}

.order-details-prod-count {
  font-size: 16px;
  font-family: MuktaMalarMedium;
}

.order-details-prod-count {
  font-size: 12px;
  font-family: MuktaMalarMedium;
}

.order-item-price {
  font-size: 12px;
  font-family: MuktaMalarMedium;
}

.order-calc-sec {
  padding: 2% 4%;
}

.order-calc-tot {
  padding: 0 4%;
  margin-bottom: 3%;
}

.order-calc-det {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-calc-head {
  font-size: 16px;
  font-family: MuktaMalarMedium;
  font-weight: 400;
  margin: 2% 0;
}

.order-calc-cont {
  font-size: 16px;
  font-family: MuktaMalarMedium;
  font-weight: 400;
  margin: 2% 0;
}

.order-details-image img {
  height: 68px;
  width: 100px;
  box-shadow: 2px 1px 5px 2px rgb(12 18 28 / 13%);
}

.order-calc-tot-head,
.order-calc-tot-cont {
  font-size: 22px;
  font-family: MuktaMalarMedium;
  font-weight: 400;
  margin: 2% 0;
}

.order-calc-tot-head1,
.order-calc-tot-cont1 {
  font-family: "Mukta Malar", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.cust-info-sec {
  border: 1.5px solid rgba(96, 31, 33, 1);
  width: 100%;
  border-radius: 6px;
  padding: 2%;
}

.cust-info-head,
.cust-info-cont,
.cust-info-head-sep {
  font-size: 16px;
  font-family: "MuktaMalarMedium", sans-serif;
  font-weight: 400;
  margin: 2% 0;
}

.cust-info-head {
  width: 45%;
  text-transform: uppercase;
}

.cust-info-cont {
  word-break: break-all;
  width: 50%;
  text-transform: capitalize;
}

.email_text {
  text-transform: lowercase !important;
}

.cust-info-det {
  display: flex;
  justify-content: space-between;
}

.pay-button {
  background-color: rgba(241, 187, 95, 1);
  border: 1px solid rgba(113, 37, 39, 1);
  border-radius: 30px;
  padding: 1% 4%;
  width: 25%;
  color: white;
  font-family: MuktaMalarMedium;
  font-size: 18px;
}

.pay-btn-sec {
  margin-top: 2%;
  padding: 0 2%;
}

.purpose {
  padding: 20px 0px;
}

.purposedata div {
  color: rgba(113, 37, 39, 1);
  font-family: MuktaMalarMedium;
  font-size: 16px;
  font-weight: 500;
}

.purposedata span {
  padding: 5px 15px 12px 0px;
}

.selectpurpose {
  margin-left: 2px;
}

.cover_purpose {
  margin-top: 10px;
}

.cover_purpose span {
  display: flex;
  align-items: center;
  float: left;
  width: auto;
}

.cover_purpose span input {
  margin-right: 5px;
}

@media (max-width: 768px) {
  .chkot-disply {
    flex-direction: column;
  }

  .checkout-div {
    border-radius: 10px;
    border-right: none;
    padding: 4%;
    text-align: center;
  }

  .checkout-secdiv {
    border-radius: 10px;
    margin: 2% 0;
    padding: 4%;
    text-align: center;
  }

  .chckot-maintxt,
  .chckot-maintxt-orders {
    padding: 4%;
  }

  .order-detail-main-sec,
  .order-details-div {
    flex-direction: column;
    padding: 30px 9px;
  }

  .left-div,
  .right-div,
  .order-details-image,
  .order-details-data {
    width: 100%;
  }

  .right-div {
    padding: 2%;
    margin-top: 4%;
  }

  .order-details-prod-name {
    text-align: center;
    margin-bottom: 1%;
  }

  .order-item-price {
    text-align: center;
    margin-bottom: 1%;
    font-size: 14px;
  }

  .order-details-data {
    margin-top: 4%;
  }

  .order-haed,
  .order-haed-billing {
    text-align: center;
  }

  .cust-info-det {
    justify-content: center;
  }

  .cust-info-head,
  .cust-info-cont {
    width: auto;
  }

  .cust-info-head-sep {
    margin: 2%;
  }

  .cust-info-sec {
    width: 100%;
  }

  .order-detail-main-sec {
    margin-top: 14%;
  }

  .image-div-cart audio {
    height: 60px !important;
  }

  .table-image {
    max-height: 100% !important;
  }

  .video-tag {
    width: 100%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .right-div {
    width: 50%;
    padding: 2% 2%;
  }

  .left-div {
    width: 50%;
  }

  .cust-info-sec {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .sub-sec-div {
    display: block;
  }

  .order-details-image img {
    width: 100%;
    height: auto;
  }

  .pay-btn-sec {
    text-align: center;
  }

  .purposedata span {
    font-size: 13px;
  }

  .order-details-image img {
    width: 100% !important;
  }

  .order-details-image img {
    height: 174px;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .sub-sec-div {
    display: block;
  }

  .order-details-image img {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .sub-sec-div {
    display: block;
  }

  .order-details-image img {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sub-sec-div {
    display: block;
  }

  .order-details-image img {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 769px) {
  .image-div-cart audio {
    height: 60px !important;
  }

  .order-details-image img {
    width: 100%;
  }
}

/* .sub-sec-div */
