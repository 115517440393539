input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
.sec-sub-header{
  text-align: center;
  color: #cd8408;
  font-family: MuktaMalarMedium;
}