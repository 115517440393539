@font-face {
    font-family: 'MuktaMalarBold';
    src: url("../fonts/mukta-malar/MuktaMalar-Bold.ttf") format("opentype");
}

@font-face {
    font-family: 'MuktaMalarNormal';
    src: url("../fonts/mukta-malar/MuktaMalar-Regular.ttf") format("opentype");
}

@font-face {
    font-family: 'MuktaMalarMedium';
    src: url("../fonts/mukta-malar/MuktaMalar-Medium.ttf") format("opentype");
}

@font-face {
    font-family: 'MuktaMalarLight';
    src: url("../fonts/mukta-malar/MuktaMalar-Light.ttf") format("opentype");
}

@font-face {
    font-family: 'AguafinaScript';
    src: url("../fonts/aguafina-script/AguafinaScript-Regular.ttf") format("opentype");
}

@font-face {
    font-family: 'Spectral';
    src: url("../fonts/spectral/Spectral-Medium.ttf") format("opentype");
}

@font-face {
    font-family: 'ButlerNormal';
    src: url("../fonts/butler/Butler_Regular.otf") format("opentype");
}

@font-face {
    font-family: 'ButlerMedium';
    src: url("../fonts/butler/Butler_Medium.otf") format("opentype");
}

@import url("https://fonts.googleapis.com/css2?family=Carattere&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Carattere&family=Mukta+Malar:wght@200;300;400;500;600;700;800&display=swap");

iframe{
    z-index: 0 !important;
    display: none;
}

body{
    background-color: rgb(255, 247, 235);
}
.bottm-footer a {
    color: #601f21;
    text-decoration: none;
}
.container_header_main{
        background-color: #FFE9C2;
    padding: 0px 34px;
    border-bottom: 1px solid #712527;
}
/* .bMgaAx div:first-child{
    text-align: center;
} */