.menuH_container nav {
  width: 100%;
  margin: 0 auto;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.menuH_container nav .megamenu_panel button {
  padding: 15px 30px;
  text-align: center;
  font-family: 'FuturaNormal';
  font-size: 13px;
  font-weight: 500;
  color: rgba(4, 4, 4, 1);
  letter-spacing: 0px;
  line-height: 17px;
  text-decoration: none;
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  width: auto;
  letter-spacing: 0.5px;
}

.menuH_container nav .megamenu_panel button span {
  font-size: 12px;
  display: none;
}

.menuH_container .header {
  display: none;
}



@media(min-width: 768px) {
  .megamenu_list {
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 46px;
    width: 100%;
    margin: 0;
    background: rgb(255, 255, 255);
    box-shadow: 0px 3px 6px rgb(179 179 179 / 50%);
  }

  .megamenu_panel:hover .megamenu_list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
}



.menuH_container .row {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}


.menuH_container .column {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-right: 1px solid rgb(241 241 241);
  padding: 15px 0px;
}

.menuH_container .column:last-child {
  border-right: none;
}

.menuH_container .column a {
  font-family: 'FuturaNormal';
  font-size: 13px;
  font-weight: 500;
  color: rgba(4, 4, 4, 1);
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.menuH_container .column a:not(last-child) {
  padding: 15px 40px;
}


.menuH_container .hamburger {
  /* width: 35px;
    height: 25px; */
  width: 25px;
  height: 16px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  display: none;
}

.menuH_container .hamburger:hover {
  cursor: pointer;
}

.menuH_container .hamburger .bar {
  width: 100%;
  height: 2px;
  background: #333;
  border-radius: 5px;
}

.menuH_container .rotate {
  transform: rotate(90deg);
  background: -webkit-linear-gradient(0deg, rgba(181, 126, 16, 1) 60%, rgba(221, 183, 78, 1) 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 767px) {
  .menuH_container .header {
    display: flex;
    justify-content: space-between;
  }

  .menuH_container .hamburger {
    display: flex;
  }

  .menuH_container nav {
    width: 50%;
    flex-direction: column;
    height: 100%;
    padding: unset;
    margin: unset;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    /* display: none; */
  }

  .displaynavdata {
    width: 50%;
    flex-direction: column;
    height: 100%;
    padding: unset;
    margin: unset;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    display: flex !important;
    transition-timing-function: ease-in;
  }

  .displaynavdata1 {
    width: 50%;
    flex-direction: column;
    height: 100%;
    padding: unset;
    margin: unset;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    display: none !important;
    transition-timing-function: ease-in;
  }

  .menuH_container nav .megamenu_panel button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menuH_container nav .megamenu_panel button span {
    display: inline;
  }

  .menuH_container nav .megamenu_panel button:hover {
    color: black;
    text-decoration: none;
    border-bottom-color: #eee;
    cursor: unset;
  }

  /* .menuH_container nav .megamenu_panel:hover .megamenu_list {
    display: none;
  } */

  .megamenu_list {
    /* display: none; */
    position: unset;
    width: 100%;
    padding: unset;
    flex-direction: column;
  }

  .menuH_container .row {
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .menuH_container .column {
    padding: 0 20px;
    margin: 0 0 10px 0;
  }
}

@media (max-width: 600px) {
  .menuH_container nav {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .menuH_container nav {
    top: 52px;
    height: calc(100vh - 52px);
  }

  .header_logo {
    width: 60%;
  }

  .menuH_container .header {
    top: 20px;
  }

  .mobile_header {
    margin-top: 50px;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .menuH_container nav {
    top: 72px;
    height: calc(100vh - 72px);
  }

  .header_logo {
    width: 85%;
  }

  .menuH_container .header {
    top: 28px;
  }

  .mobile_header {
    margin-top: 70px;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .menuH_container nav {
    top: 95px;
    height: calc(100vh - 95px);
  }

  .header_logo {
    width: 80%;
  }

  .menuH_container .header {
    top: 38px;
  }

  .mobile_header {
    margin-top: 90px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .menuH_container .column a:not(last-child) {
    padding: 15px 25px;
  }
}

@media (max-width: 767px) {
  .menuH_container .header {
    padding-left: 20px;
    width: 45px;
    position: fixed;
    left: 0px;
    /* z-index: 9;
    position: relative; */
  }

  /* .site_nav {
    position: fixed;
    background-color: rgb(255, 255, 255);
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 9;
  } */

  .megamenu_list {
    left: 0px !important;
    transform: translateX(0%) !important;
  }

  .megamenu_panel {
    width: 100%;
  }

  .megamenu_list .column {
    border-right: none;
    width: 100%;
  }

  .menuH_container .column a {
    border-bottom: 1px solid rgb(235 235 235);
  }

  .menuH_container nav {
    position: fixed;
    background-color: rgb(255, 255, 255);
    left: 0px;
    width: 100%;
    overflow: auto;
  }

  .menuH_container nav .megamenu_panel:first-child {
    border-top: 1px solid rgba(211, 211, 211, 0.2);
  }

  .menuH_container nav .megamenu_panel button {
    border-bottom: 1px solid rgb(247 246 250);
  }
}

/* Animation for hamburger menu */
.animInfo {
  animation-duration: .5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
}

@keyframes bar1Anim {
  0% {
    transform: rotate(0deg) translate(0px);
  }

  /* 100%{transform: rotate(45deg) translate(6px, 10px);} */
  100% {
    transform: rotate(45deg) translate(3px, 8px);
  }
}

@keyframes bar2Anim {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes bar3Anim {
  0% {
    transform: rotate(0deg) translate(0px);
  }

  /* 100%{transform: rotate(-45deg) translate(6px, -10px);} */
  100% {
    transform: rotate(-45deg) translate(3px, -6px);
  }
}

@keyframes bar1AnimReverse {

  /* 0%{transform: rotate(45deg) translate(6px, 10px);} */
  0% {
    transform: rotate(45deg) translate(3px, 8px);
  }

  100% {
    transform: rotate(0deg) translate(0px);
  }
}

@keyframes bar2AnimReverse {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bar3AnimReverse {

  /* 0%{transform: rotate(-45deg) translate(6px, -10px);} */
  0% {
    transform: rotate(-45deg) translate(3px, -6px);
  }

  100% {
    transform: rotate(0deg) translate(0px);
  }
}

/* Animation for navigation appearing */
@keyframes navAnim {
  0% {
    opacity: 0;
    width: 0%;
  }

  100% {
    opacity: 1;
    width: 100%;
  }
}

@keyframes navAnimReverse {
  0% {
    opacity: 1;
    width: 100%;
  }

  100% {
    opacity: 0;
    width: 0%;
  }
}

@media (min-width: 801px) {
  nav {
    opacity: 1 !important;
  }
}

@media (min-width: 768px) {
  .menuH_container .column {
    width: 15%;
  }
}

@media (max-width: 767px) {
  .menuH_container nav.menuopen {
    width: 100%;
    opacity: 1;
    visibility: visible;
    transition: 0.5s all ease-in-out;
  }

  .menuH_container .menuclose {
    width: 0px;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s all ease-in-out;
  }


  .megamenu_list {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }

  .megamenu_list.show {
    transition: max-height 0.5s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
  }

  .megamenu_panel button span {
    transform: rotate(0deg);
    transition: 0.5s all ease-in-out;
  }

  .megamenu_panel button.active span {
    transform: rotate(90deg);
    transition: 0.5s all ease-in-out;
  }
  .hamburger .bar {
    transition: 0.5s all ease-in-out;
  }
  
  .rotate_hamburger .bar:nth-child(1) {
    transform: rotate(45deg) translate(3px, 8px);
    transition: 0.5s all ease-in-out;
  }
  
  .rotate_hamburger .bar:nth-child(2) {
    display: none;
    transition: 0.5s all ease-in-out;
  }
  
  .rotate_hamburger .bar:nth-child(3) {
    transform: rotate(-45deg) translate(3px, -6px);
    transition: 0.5s all ease-in-out;
  }
}



/* ----------- iPhone 5, Portrait and Landscape ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) {
  .megamenu_list {
    visibility: hidden;
    opacity: 0;
    transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
  }

  .megamenu_list.show {
    visibility: visible;
    opacity: 1;
  }

  .megamenu_panel button span {
    transition: 0.2s all ease-in-out;
  }
}


/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .megamenu_list {
    visibility: hidden;
    opacity: 0;
    transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
  }

  .megamenu_list.show {
    visibility: visible;
    opacity: 1;
  }

  .megamenu_panel button span {
    transition: 0.2s all ease-in-out;
  }
}


/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .megamenu_list {
    visibility: hidden;
    opacity: 0;
    transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
  }

  .megamenu_list.show {
    visibility: visible;
    opacity: 1;
  }

  .megamenu_panel button span {
    transition: 0.2s all ease-in-out;
  }
}





