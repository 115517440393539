@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;1,100&family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mukta+Mahee:wght@200;300;400;500;600;700;800&family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Carattere&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Carattere&family=Mukta+Malar:wght@200;300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.img_tag_div {
    background-color: #fff;
    float: left;
    width: 100%;
    padding: 20px;
}

.simpleSubmitButton {
    width: 82px;
    background-color: rgb(0 0 0);
    border: none;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    padding: 7px;
    /* margin-left: 150px; */
    float: right;
    /* margin-top: 20px; */
    /* margin-top: 0px; */
    z-index: 999999;
    margin-bottom: 2px;
}
.simpleSubmitButton1 {
    width: 82px;
    background-color: rgb(0 0 0);
    border: none;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    padding: 7px;
    /* margin-left: 150px; */
    float: right;
    /* margin-top: 20px; */
    /* margin-top: 0px; */
    z-index: 999999;
    pointer-events: none;
    opacity: 0.4;
    margin-bottom: -39px;
}
.submitdiv{
  align-items: center;
}
/*style for inputs*/
.simpleInput {
  width: 100%;
  max-width: 60vw;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: 1px solid #000000 !important;
  box-shadow: 1px 1px 4px #ebebeb;
  -moz-box-shadow: 1px 1px 4px #ebebeb;
  -webkit-box-shadow: 1px 1px 4px #ebebeb;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
}
.simpleInput:focus {
  border: 1px solid #21c6e1;
}
/*style for file upload*/
.fileUpload {
  position: relative;
  width: 10vw;
}
/* .inputUpload {
  position: justify;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: 0;
} */
.fileText {
  position: absolute;
  font-weight: bold;
  font-size: 10vw;
  width: 10vw;
  height: 90%;
  color: #000607;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.spanUpload {
    position: absolute;
    height: 10%;
    width: 10vw;
    font-size: 18px;
    color: #ffffff;
    text-align: left;
    vertical-align: middle;
    justify-content: center;
    display: flex;
    flex-direction: column;
    top: 19%;
    margin-left: 16px;
  
}
.spanUpload1 {
    position: absolute;
    height: 10%;
    width: 10vw;
    font-size: 18px;
    color: #dc0a0a;
    text-align: left;
    vertical-align: middle;
    justify-content: center;
    display: flex;
    flex-direction: column;
    top: 14%;
    margin-left: 16px;
  
}
.iii{
  color: #171717;
  background-color: #171717;
  padding: 100px 0px;
}




.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.bbb{
  background-color: green;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.alert {
    width: 300px !important;
    position: fixed !important;
    bottom: 10px !important;
    right: 20px !important;
    z-index: 9;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 50%);
}

.CookieConsent{
  padding: 0px 0px 0px 100px;
  
}
@media (max-width: 768px) {
  .CookieConsent{
    display: block !important;
  }
}
.flt {
  float: left;
  width: 100%;
}

.header {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.header a.home-menu,
.header-logo {
  /* float: left; */
  padding: 2%;
  text-decoration: none;
  line-height: 25px;
  text-align: center;
  font-family: MuktaMalarNormal;
  font-size: 14px;
  font-weight: 500;
  color: #712527;
}

/* .header-logo img {
  width: 100%;
} */

.header a.header-logo {
  padding: 0;
}

.header a:hover {
  background-color: transparent;
  color: #c98f2c;
}

.header a.active {
  background-color: transparent;
  color: #c98f2c;
}

.header-cont {
  float: left;
  /* padding: 0 5%; */
  width: 100%;
}

.header-right {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.header-right a {
  text-decoration: none;
}

.logn-bttn {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.sinup-btton {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: left;
}

.menu-main {
  background-color: rgba(244, 230, 209, 1);
  color: rgba(113, 37, 39, 1);
  padding: 14px 0px;
  background-color: #FFE9C2;
  ;
}

.menuH li {
  display: inline-block;
  padding: 0px 12px;
}

.menuH li a {
  text-align: center;
  font-family: MuktaMalarNormal;
  font-size: 14px;
  font-weight: 500;
  color: #c98f2c;
  text-decoration: none;
}

.header-sec {
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
}

.logsign-btton {
  display: flex;
}

.logn-bttn a {
  border: 1px solid rgba(113, 37, 39, 0.6);
  border-radius: 26px;
  padding: 4px 20px;
  font-family: MuktaMalarBold;
  font-size: 10px;
  font-weight: 400;
  color: rgba(113, 37, 39, 1);
  background-color: transparent;
  text-decoration: none;
}

.sinup-btton a {
  border: 1px solid rgba(113, 37, 39, 0.6);
  border-radius: 26px;
  padding: 4px 20px;
  font-family: MuktaMalarBold;
  font-size: 10px;
  font-weight: 400;
  color: rgba(113, 37, 39, 1);
  background-color: #f4e6d1;
  text-decoration: none;
}

.cart-count {
  font-size: 10px;
  background-color: white;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #c98f2c;
}

.cart-sec {
  display: flex;
}

.logn-bttn button.btton-log:hover {
  background-color: #712527;
  border: 1px solid #230809;
  border-radius: 26px;
  color: #ffffff;
}

.sinup-btton button.btton-sign:hover {
  background-color: #712527;
  border: 1px solid #230809;
  border-radius: 26px;
  color: #ffffff;
}

.slder-div {
  background: url(/static/media/bannerimg.693f9465.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bannermaindiv {
  display: flex;
  flex-direction: row;
}

.bannr-maintext {
  font-family: "Carattere", cursive;
  font-size: 43px;
  font-weight: 400;
  color: #712527;
  text-align: left;
  line-height: 63px;
}

.bannr-text {
  font-family: "Butler";
  font-size: 25px;
  font-weight: 300;
  color: #712527;
  line-height: 41px;
}

.cart-div {
  text-align: center;
}

.yakshagana-maindiv {
  background: url(/static/media/yakshagana.e0afe8b3.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 42px 0px;
}

.yakshagana-div {
  padding: 20px 0px;
}

.yakshagana-text {
  font-family: ButlerNormal;
  font-size: 22px;
  font-weight: 300;
  color: #ffffff;
  text-align: justify;
  padding: 0px 26px;
}

.yakshagana-firsttxt {
  position: absolute;
  top: 97px;
  left: -116px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  text-transform: uppercase;
  font-family: "Marcellus";
  font-size: 15px;
  font-weight: 400;
  color: #f4e8d4;
  text-align: center;
}

.yakshagana-sectxt {
  position: absolute;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  top: 59px;
  right: -38px;
  text-transform: uppercase;
  font-family: "Marcellus";
  font-size: 15px;
  font-weight: 400;
  color: #f4e8d4;
  text-align: center;
}

.yakshagana-thirdtxt {
  position: absolute;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  top: 52px;
  right: -33px;
  text-transform: uppercase;
  font-family: "Marcellus";
  font-size: 15px;
  font-weight: 400;
  color: #f4e8d4;
  text-align: center;
}

.yakshagana-img {
  position: relative;
  width: 100%;
}

.yakshagana-img img {
  width: 100%;
}

.yakshagana-toptop {
  position: relative;
  padding: 6px 0px;
}

.yakshagana-topbtm {
  position: relative;
  padding: 6px 0px;
}

.yakshagana-righttop {
  position: relative;
  padding: 0px 40px;
}

.yakshagana-righttop img {
  width: 100%;
}

.yakshagana-rightbttm {
  padding: 0px 40px;
}

.yakshagana-rightbttm img {
  width: 100%;
}

.hrdividr {
  height: 4px !important;
  width: 100%;
  color: rgba(201, 143, 44, 1);
}

.yakshagana-sectext {
  font-family: MuktaMalarNormal;
  font-size: 28px;
  font-weight: 400;
  color: #c98f2c;
  text-align: right;
}

.cultural-maindiv {
  background-color: rgb(255, 247, 235);
  padding: 10px 0px;
}

.cultural-text {
  font-family: ButlerBold;
  font-size: 28px;
  font-weight: bold;
  color: #712527;
  text-align: center;
  padding: 2% 0;
}

.cultr-imgright {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cultr-img {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.cultural-img-maindiv .col-sm-3 {
  padding-left: 0.5%;
  padding-right: 0.5%;
}

.cultr-div {
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 6px;
  border-color: #969492;
  border-width: 1px;
  border-radius: 10px;
  border-style: solid;
}

.cultural-img-maindiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 12px 0px 40px 0px;
}

.cultur-text {
  font-size: 13px;
  color: #585756;
  font-family: ButlerNormal;
  text-align: left;
  padding: 8px 0px;
  display: flex;
  width: 100%;
}

.cultural-buttn button {
  border: 1px solid #712527;
  border-radius: 26px;
}

.cultural-buttn {
  text-align: center;
  padding: 28px 0px;
}

.strypurps-main {
  padding: 10px 0px;
}

.stry-purpseimg {
  text-align: center;
}

.stry-purpshead {
  font-family: "Carattere", cursive;
  font-size: 43px;
  font-weight: 400;
  color: #712527;
  line-height: 63px;
}

.stry-purpsetext {
  font-family: ButlerNormal;
  font-size: 20px;
  font-weight: 300;
  color: #712527;
  text-align: justify;
}

.stry-purpsedivdr {
  color: #c98f2c;
  float: right !important;
}

.stry-purpsbht {
  font-family: MuktaMalarNormal;
  font-size: 28px;
  font-weight: 400;
  color: #c98f2c;
  line-height: 28px;
  text-align: right;
}

.strypurps-rightimg img {
  width: 100%;
}

.phtfootgmus-maindiv {
  background: url(/static/media/vistng.5940f7d1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.phtftgmsc-div {
  padding: 12px 0px 42px;
}

.phtftgmus-img img {
  width: 100%;
}

.visiting-main {
  padding: 68px 0;
}

.visiting-img img {
  width: 100%;
}

.visiting-text {
  font-family: ButlerNormal;
  font-size: 20px;
  font-weight: 300;
  color: #ffffff;
  text-align: left;
  padding-left: 4%;
  padding-right: 4%;
}

.visiting-main .row {
  background-color: #c98f2c;
}

.visiting-main .col-sm-5 {
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  display: flex;
  height: 100%;
}

.visiting-main .col-sm-7 {
  padding-left: 0px;
  padding-right: 0px;
}

.visiting-main .container {
  max-width: 100%;
}

.bottom-mainbanner {
  background: url(/static/media/yakshagana.e0afe8b3.png);
  padding: 62px 0px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bottm-bannertext {
  font-family: ButlerMedium;
  font-size: 28px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 41px;
  margin-bottom: 28px;
}

.botm-bannergallry {
  padding: 10px 0px;
}

.botm-bannergallry ul {
  padding: 0px;
  margin-bottom: 0px;
}

.bottom-mainbanner .container {
  /* max-width: 100%; */
  padding-left: 0px;
  padding-right: 0px;

}

.bottmrow-gallry {
  display: flex;
}

.bottmrow-gallry img {
  width: 100%;
}

.footer-menu li {
  display: inline-block;
  padding: 0px 30px;
}

.footer-menu li a {
  text-align: center;
  text-decoration: none;
  font-family: MuktaMalarNormal;
  font-size: 12px;
  font-weight: 500;
  color: rgba(113, 37, 39, 1);
}

.footer-back {
  font-family: MuktaMalarNormal;
  font-size: 10px;
  font-weight: 400;
  color: #712527;
  letter-spacing: 0px;
  line-height: 12px;
  display: flex;
  justify-content: flex-end;
}

.back-to-top-btn {
  border: none;
  background: none;
  display: flex;
  align-items: center;
}

.bottm-footer {
  font-family: MuktaMalarNormal;
  font-size: 13px;
  font-weight: 400;
  color: #712527;
  line-height: 25px;
}

.footer-logo {
  text-align: center;
}

.footer-main {
  border-top: 1px solid rgb(255 233 194);
  padding: 10px 0px;
  background-color: rgb(255, 247, 235);
}

.footer-menu {
  padding: 0px;
  margin: 0px;
}

.footer-txtbtn {
  display: flex;
  text-align: right;
}

.footer-sec-last {
  justify-content: center;
  display: flex;
  align-items: center;
}

.back-to-top {
  font-family: MuktaMalarNormal;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #712527;
  margin-right: 15px;
}

.footer-content {
  text-align: center;
}

.rrc-bannerimg,
.bannermaindiv {
  display: flex !important;
  justify-content: space-between;
}

.bannermaindiv {
  padding: 10px 10px;
}

.rrc-bannerimg div {
  display: flex;
  align-items: center;
}

.banner-txt {
  padding-left: 24px;
}

.bannr-dividr hr {
  color: rgba(201, 143, 44, 1);
  width: 15%;
  height: 2px !important;
}

.responsive-header {
  display: none;
}

.image-main-div {
  width: 25%;
  display: flex;
  margin: 0px 6px;
}

.cultr-imgone {
  margin-bottom: 8px;

}

.cultr-imgone img,
.cultr-imgtwo img,
.cultr-imgthree img {
  width: 100%;
  height: 100%;
}

.cultr-imgthree {
  margin-left: 8px;
  width: 50%;
}

.all-coctn-div {
  padding: 0px 462px;
  margin: 40px 0px;
}

.all-collctn {
  color: rgba(113, 37, 39, 1);
  border: 1px solid rgba(113, 37, 39, 0.6);
  border-radius: 20px;
  font-family: MuktaMalarMedium;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 2px;
  text-align: center;
  padding: 6px 20px !important;
  font-size: 14px;
  text-transform: uppercase;
  width: auto !important;
  margin: 0% auto;
}

.all-collctn:hover {
  background-color: rgba(113, 37, 39, 0.6);
  color: white;
}

.cultr-imgone img {
  border-radius: 10px;
}

.cultr-imgtwo img {
  border-radius: 10px;
}

.cultr-imgthree img {
  border-radius: 10px;
}

.phtftgmus-img {
  position: relative;
  cursor: pointer;
}

.colc {
  text-align: center !important;
  margin-top: 26px;
}

.phtftgmus-img .view_more {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 0px;
  opacity: 0;
  visibility: hidden;
  height: 0px;
  background-color: rgb(194 130 19 / 60%);
  transition: 0.3s all ease-in-out;
}

.phtftgmus-img:hover .view_more {
  width: 100%;
  height: 100%;
  visibility: visible;
  opacity: 1;
  transition: 0.3s all ease-in-out;
}

.phtftgmus-img .view_more span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 9;
  color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 4px;
  width: 120px;
  text-align: center;
}

.copyright {
  padding: 25px 0px;
  background-color: #FFE9C2;
}

@media (max-width: 768px) {
  .yakshagana-firsttxt {
    position: absolute;
    top: 95px;
    left: -97px;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    text-transform: uppercase;
    font-family: "Marcellus";
    font-size: 15px;
    font-weight: 400;
    color: #f4e8d4;
    text-align: center;
  }

  .yakshagana-sectxt {
    position: absolute;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    top: 52px;
    right: -20px;
    text-transform: uppercase;
    font-family: "Marcellus";
    font-size: 12px;
    font-weight: 400;
    color: #f4e8d4;
    text-align: center;
  }

  .yakshagana-thirdtxt {
    position: absolute;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    top: 44px;
    right: -18px;
    text-transform: uppercase;
    font-family: "Marcellus";
    font-size: 12px;
    font-weight: 400;
    color: #f4e8d4;
    text-align: center;
  }

  .bottm-bannertext {
    font-size: 20px;
  }

  .visiting-text {
    padding-top: 4%;
    padding-bottom: 4%;
    text-align: center;
  }

  .phtftgmus-img {
    padding: 10px 10px;
  }

  .stry-purpshead {
    line-height: 40px;
    text-align: center;
    padding: 5% 2%;
  }

  .stry-purpsetext {
    text-align: center;
    padding: 2%;
  }

  .stry-purpsbht {
    text-align: center;
  }

  .strypurps-rightimg {
    padding: 2%;
  }

  .bannermaindiv {
    display: block !important;
    padding: 0;
  }

  .bannr-dividr {
    display: flex;
    justify-content: center;
  }

  .bannr-maintext,
  .bannr-text {
    text-align: center;
    /* font-size: 20px; */
  }

  .bannr-text {
    padding-bottom: 10%;
    font-size: 20px;
  }

  .footer-menu {
    padding: 0;
  }

  .bottm-footer {
    text-align: center;
  }

  .cultural-img-maindiv {
    flex-direction: column;
  }

  .cultural-img-maindiv .image-main-div {
    padding: 2% 4%;
  }

  .header {
    display: none !important;
  }

  .responsive-header {
    display: flex;
    padding: 0 3%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(244, 230, 209, 1);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }

  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 14px;
    color: rgba(113, 37, 39, 1);
    font-family: MuktaMalarNormal;
    display: block;
    transition: 0.3s;
  }

  .sidenav a:hover {
    color: #f1f1f1;
  }

  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  .rrc-bannerimg div:first-child img {
    width: 100%;
  }

  .cultr-img {
    justify-content: space-between;
    width: 50%;
  }

  .image-main-div {
    width: 100%;
  }

  .all-coctn-div {
    padding: 0 25%;
  }

  .banner-txt {
    padding: 0px;
    font-size: 20px;
  }
}

@media only screen and (min-device-width: 769px) and (max-device-width: 1200px) {
  .header-cont {
    padding: 0 3%;
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }

  .banner-txt {
    padding: 0px;
    font-size: 20px;
  }

}

@media (max-width: 767px) {
  .rrc-bannerimg {
    justify-content: center;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .banner-txt {
    padding: 0px;
    font-size: 20px;
  }

  .footer-menu li {
    padding: 0px 8px;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .footer-menu li {
    padding: 0px 10px;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .footer-menu li {
    padding: 0px 10px;
  }

  .yakshagana-maindiv .col-sm-4 {
    width: 100%;
  }

  .cultural-maindiv .col-sm-6 {
    width: 100%;
  }

  .visiting-main .col-sm-7,
  .visiting-main .col-sm-5 {
    width: 100%;
  }

  .blog-div .col-sm-6 {
    width: 100%;
  }

  .event-main .col-sm-3 {
    width: 50%;
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .footer-menu li {
    padding: 0px 10px;
  }

  .rrc-bannerimg {
    justify-content: center;
  }

  .yakshagana-maindiv .col-sm-4 {
    width: 100%;
  }

  .cultural-maindiv .col-sm-6 {
    width: 100%;
  }

  .visiting-main .col-sm-7,
  .visiting-main .col-sm-5 {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .all-coctn-div {
    padding: 0px;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.invalid-mob {
  display: block !important;
}
.login_container {
  width: 100%;
  padding: 2% 15%;
  background-color: #FFF6E8;
}
.image-sec-right {
  width: 100%;
  background-image: url(/static/media/bg-image.fdc14e04.png);
}
.login_box {
  display: flex;
  flex-direction: row;
}
.loginimg{
  text-align: center;
}
.login_cont {
  display: flex;
  flex-direction: column;
  padding: 1% 5%;
  width: 100%;
  background-color: white;
}
.sec-header {
  font-family: MuktaMalarMedium;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: 0;
  color: #712527;
  text-align: center;
  text-transform: uppercase;
}
.input-sec {
 border-radius: 25px;
    border-color: #712527;
    padding: 19px 30px;
    width: 100%;
    border: 1px solid rgba(96, 31, 33, 1);
    font-family: MuktaMalarMedium;
    font-size: 12px;
    letter-spacing: 1px;
    outline: none;
}
.form-group.flt {
  margin: 2.5% 0;
}
.login_btn {
  padding: 15px;
  background-color: #712527;
  border-radius: 25px;
  border-color: #712527;
  border-width: 1.5px;
  font-family: MuktaMalarNormal;
  box-shadow: none;
  color: white;
  margin: 12px 0px;
}
.input-sec::-webkit-input-placeholder {
  color: #c98f2c;
  font-family: MuktaMalarMedium;
  text-transform: uppercase;
}
.input-sec:-ms-input-placeholder {
  color: #c98f2c;
  font-family: MuktaMalarMedium;
  text-transform: uppercase;
}
.input-sec::placeholder {
  color: #c98f2c;
  font-family: MuktaMalarMedium;
  text-transform: uppercase;
}
.forgot-pass-sec {
  display: flex;
  margin: 0 0 2% 0;
  justify-content: flex-end;
}
.forgot-password {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  font-family: MuktaMalarMedium;
  color: #c98f2c;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.reg-sec {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 7% auto;
}
.reg-sec-content {
  color: #712527;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.invalid-feedback {
  font-family: MuktaMalarMedium;
  font-size: 12px;
  margin: auto 2%;
}
.right-sec-header {
  color: #c98f2c;
  font-family: AguafinaScript;
  font-size: 36px;
}
.right-sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  align-self: center;
  justify-content: center;
  height: 100%;
  padding: 0 5%;
}
.right-sec-desc{
  font-family: Spectral;
  color: rgba(244, 230, 209, 1);
  font-size: 16px;
  text-align: center;
}
.reg-sec-content a{
  color: #712527;
  text-decoration: none;
}
.reg-sec-content a:hover{
  cursor: pointer;
}
.login_btn:hover{
  cursor: pointer;
}

@media (max-width: 768px) {
  .login_box{
    flex-direction:column-reverse;
  }
  .right-sec{
    padding: 5%;
  }
  .login_container{
    padding: 4% 5%;
  }
}
.invalid-mob {
  display: block !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

input[type=number] {
-moz-appearance: textfield;
}
.pagination {
  justify-content: flex-end;
  margin-top: 40px !important;
}
.pagination li {
  margin-left: 10px;
}
.pagination li a {
  border-radius: 5%;
  width: 25px;
  height: 25px;
  line-height: 25px;
  display: block;
  border: 1px solid rgb(0, 0, 0);
  text-align: center;
  font-size: 11px;
}
.displayopacity {
    pointer-events: none;
    opacity: 0.5;
}


.pagination li:first-child a,
.pagination li:last-child a {
  width: auto;
  height: auto;
  border: none;
  text-transform: uppercase;
}
.pagination li.active a {
  background-color: transparent;
  color: #c98f2c !important;
}
.image-sec.image,
.image-sec.audio,
.image-sec.video {
  width: 100%;
}
.list-main-sec {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3% 5%;
  background-color: rgb(255, 247, 235);
}
.cartpg.flt {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  text-align: center;
  background-color: rgb(255 255 255 / 90%);
  z-index: 9;
  display: flex;
  align-items: center;
  width: 100%;
  align-content: center;
  justify-content: center;
}
.no-data {
  text-align: center;
  padding: 2% 0;
  width: 100%;
}
.audio {
  width: 100%;
}
.modal-dialog {
  width: 50% !important;
}
.bMgaAx div:first-child {
  width: 100%;
}
.tbl-cell-data {
  width: 100% !important;
}
img.image-sec {
  cursor: pointer;
}
.image-sec-list {
  width: 100%;
  max-height: 90px;
}
.table-image-cart {
  width: 100%;
  max-height: 500px !important;
}
.datevisible{
  visibility: hidden;
}
.close-modal-sec-table {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 9999999;
}
.dFsTSm{
  overflow: visible !important;
  overflow: initial !important;
  white-space: normal !important;
}
.tbl_data {
    /* display: none; */
    visibility: hidden;
}

#enquiryModal .modal-body {
  background-color: rgba(244, 230, 209, 1);
}
.pop-up-close-table {
  border: none;
  background: black;
  box-shadow: none;
  font-size: 28px;
  padding: 0;
  color: wheat;
  border-radius: 20px;
  width: 28px;
  height: 28px;
  line-height: 0;
}
.table-pop-up-img,
.table-pop-up-aud {
  border-radius: 20px;
  padding: 1%;
}
.table-pop-up-aud {
  display: flex;
  flex-direction: column;
}
.table-pop-up-aud audio {
  width: 100%;
}
.reset-btn-filter{
    width: 15%;
    box-shadow: none;
    font-size: 15px;
    background-color: transparent;
    border: 1px solid rgba(201, 143, 44, 0.38);
}
.reset-btn-filter:hover{
    background-color: rgba(113, 37, 39, 0.6);;
    color: white;
}
.list-filter-sec-div{
    display: flex;
    flex-direction: row;
    width: 70%;
}
.tbl-cell-data.img{
    cursor: pointer;
}

@media (max-width: 768px) {
  .table-pop-up-aud1{
    display: block;
  }
  .table-image-cart {
    max-height: 100% !important;
  }
  .eUeqdG {
    white-space: inherit !important;
    text-overflow: inherit;
    overflow: visible !important;
    overflow: initial !important;
  }
  .filter-search {
    margin-top: 9px;
    height: 45px;
  }
  .list-filter-sec-div{
      padding: 0 !important;
  }
  .reset-btn-filter{
      margin-top: 9px;
      width: 35%;
  }
  .tbl-cell-data:hover .tbl_data {
    visibility: visible;
    background-color: black;
    color: white;
    white-space: normal !important;
    white-space: initial !important;
    overflow: visible !important;
    text-overflow: ellipsis;
    width: -webkit-fit-content  !important;
    width: -moz-fit-content  !important;
    width: fit-content  !important;
}

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
.sec-sub-header{
  text-align: center;
  color: #cd8408;
  font-family: MuktaMalarMedium;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
.sec-sub-header{
  text-align: center;
  color: #cd8408;
  font-family: MuktaMalarMedium;
}
.flt {
  float: left;
  width: 100%;
}

.filler {
  flex-grow: 1;
}

.data_text {
  font-family: 'Mukta Malar';
  font-size: 31px;
  font-weight: 500;
  color: rgba(113, 37, 39, 1);
  margin: 36px 0px;
}

.main-div {
  float: left;
  width: 100%;
}

.image-div-list {
  width: 100%;
  /* padding: 1%; */
  border-radius: 10px;
  border: 1px solid rgba(255, 233, 194, 1);
  margin-top: 30px;
}

.image-sec-list-page {
  width: 100%;
  height: 100%;
  min-height: 198px;
}

.image-data-sec {
  width: 100%;
  padding: 15px 10px;

  /* flex-direction: row; */
  /* border-color: rgba(12, 18, 28, 0.12); */
  /* border-style: solid; */
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-left-radius: 8px;
  background-color: #FFE9C2;
  border-bottom-right-radius: 8px;
}

.image-data-name-sec {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}

.image-data-name {
  font-weight: 400;
  font-size: 18px;
  color: rgb(113 37 39);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 75%;
}

.image-data-amount {
  font-size: 15px;
  margin-bottom: 10px;
  color: #000000;
}

.image-data-count-sec {
  width: 25%;
  text-align: right;
}

.display_btn {
  width: 50%;
}

.display_price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.image-data-count {
  font-size: 15px;
  color: rgb(12, 12, 12);
  margin: 0px !important;
}

.detail-sec-item img {
  border-radius: 10px 10px 0px 0px;
  height: 215px;
}

.detail-sec-item.audio img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}

.css-26l3qy-menu {
  background-color: rgba(244, 230, 209, 1) !important;
}

.modal-dialog {
  max-width: 800px !important;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border-bottom: 2px solid rgb(234 199 137);
}

.eUeqdG {

  white-space: unset !important;

}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 1.5% 3%;
  transition: 0.3s;
  font-size: 18px;
  font-family: MuktaMalarMedium;
  color: rgb(67, 67, 67);
}

/* Change background color of buttons on hover */
.tab button:hover {
  color: rgba(201, 143, 44, 1);
}

/* Create an active/current tablink class */
.tab button.active {
  color: rgb(113 37 39);
  border-bottom: 2px solid rgb(113 37 39);
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.search_main .col-sm-4 {
  padding-right: 0px;
}

.search_left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search_left button {
  font-size: 14px;
  font-weight: 400;
  background: #FFF6E8;
  border: 1px solid #712527;
  border-radius: 3px;
  padding: 8px 10px;
  margin-right: 5px;
  width: 33.33%;
}

/* .search_left button:hover {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(113, 37, 39, 1);
  border: 1px solid rgba(255, 197, 99, 1);
} */
.imagebutton {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(113, 37, 39, 1) !important;
  border: 1px solid rgba(255, 197, 99, 1);
}

.search_main .col-sm-8 {
  padding-left: 0px;
}

.search_right {
  position: relative;
}

.search_right input {
  font-size: 15px;
  font-weight: 400;
  background: #FFF7EB;
  border: 1px solid #EAC789;
  box-shadow: 0px 2px 3px rgba(12, 18, 28, 0.15);
  border-radius: 7px;
  padding: 10px 20px;
  outline: none;
  width: 100%;
}

.search_right input::-webkit-input-placeholder {
  color: rgba(138, 138, 138, 0.87);
}

.search_right input:-ms-input-placeholder {
  color: rgba(138, 138, 138, 0.87);
}

.search_right input::placeholder {
  color: rgba(138, 138, 138, 0.87);
}

.src_icon {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: rgba(113, 37, 39, 1);
  border-radius: 0px 6px 6px 0px;
  padding: 10px 20px;
}

.src_icon i {
  color: rgba(255, 255, 255, 1);
}



@media (max-width: 768px) {
  .list-filter-sec .list-custom-class__control {
    padding: 2% 2%;
  }

  .main-div {
    flex-direction: column;
  }

  .image-div-list {
    width: 100%;
    max-height: 100%;
  }

  div [role="gridcell"] {
    width: 120px !important;
  }

  .list-filter-sec {
    flex-direction: column !important;
  }

  .custom-drop-down {
    width: 100% !important;
  }

  .list-filter-sec select,
  .custom-drop-down:nth-child(2) {
    padding: 1% 2% !important;
  }

  .tab button {
    padding: 2% 4%;
  }

  .tab {
    margin: 2% 0;
  }

  #enquiryModal {
    justify-content: center;
  }

  .content-div {
    width: 100% !important;
    margin-top: 3%;
    margin-left: 0 !important;
    text-align: center;
  }

  .image-div {
    width: 100% !important;
  }

  .modal-dialog {
    width: 95% !important;
  }
}

.pagination {
  justify-content: flex-end;
  margin-top: 40px !important;
}

.pagination li {
  margin-left: 10px;
}

.pagination li a {
  border-radius: 10%;
  width: 30px;
  height: 30px;
  line-height: 28px;
  display: block;
  border: 1px solid #a39f9f;
  text-align: center;
  font-size: 12px;
  color: #717171;
}

.pagination li:first-child a,
.pagination li:last-child a {
  width: auto;
  height: auto;
  border: none;
  text-transform: uppercase;
}

.pagination li.active a {
  background-color: transparent;
  color: #712527 !important;
  border: 1px solid #712527;
}

.rdt_TableHead,
.rdt_TableHead .rdt_TableHeadRow,
.rdt_TableBody .rdt_TableRow {
  background-color: #FFF7EB;
}

.rdt_TableHeadRow div {
  font-family: MuktaMalarMedium;
  color: #535151;
  font-size: 14px;
}

.rdt_TableBody .rdt_TableRow {
  padding: 2% 0;
  border-color: rgba(201, 143, 44, 0.38) !important;
}

.rdt_TableRow div div {
  /* display: flex; */
  align-items: center;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rdt_TableRow div div span {
  padding-left: 2%;
}

.rm-cart-button1 {
  border: 1px solid rgba(148, 148, 148, 1);
  border-radius: 58px;
  background: transparent;
  padding: 5px 20px;
  font-family: 'Mukta Malar';
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.cart button {
  width: 100px;
}

.add-to-cart {
  width: 100%;
  border: 1px solid rgb(113, 37, 39);
  border-radius: 58px;
  background-color: rgb(255, 255, 255);
  padding: 5px 28px;
  font-family: 'Mukta Malar';
  font-size: 13px;
  font-weight: 400;
  color: rgb(113, 37, 39);
}

.add-to-cart.image {
  padding: 10%;
  width: 100%;
}

.add-to-cart.video {
  padding: 10%;
  width: 100%;
}

.add-to-cart.audio {
  padding: 10%;
  width: 100% !important;
}

.add-to-cart:hover {
  background-color: rgba(113, 37, 39, 0.6);
  color: white;
  /* width: 100% !important; */
}

.list-filter-sec {
  display: flex;
  flex-direction: row;
  /* box-shadow: 0 2px 0px 0 rgba(12, 18, 28, 0.12); */
  margin-bottom: 10px;
}

.custom-drop-down {
  width: 30%;
}

.custom-drop-down .list-custom-class__option {
  background-color: transparent;
  padding: 0;
}

.list-custom-class__option .option-sec:hover {
  background-color: rgb(189, 189, 189);
  color: white;
  cursor: pointer;
}

.option-sec {
  display: flex;
  align-items: center;
  width: 100%;
}

.option-sec img {
  margin-right: 5%;
}

.css-1okebmr-indicatorSeparator {
  background-color: transparent !important;
}

.list-filter-sec select,
.list-filter-sec .list-custom-class__control {
  padding: 2.8% 2%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-color: rgba(201, 143, 44, 0.38);
  color: #0c121c;
  font-family: MuktaMalarBold;
  border-right-color: transparent;
  background-color: transparent;
  outline: none;
}

.list-filter-sec input,
.list-filter-sec input:active,
.list-filter-sec input:focus {
  width: 100%;
  border: 1px solid rgba(201, 143, 44, 0.38);
  border-radius: 5px;
  font-family: MuktaMalarNormal;
  background-color: transparent;
  border-width: 1px;
  padding: 1% 2%;
  outline: none;
}

.table-sec {
  margin: 2% 0;
}

div [role="gridcell"] {
  width: 100px !important;
}

div [role="gridcell"]:last-child div {
  width: 100%;
}

.table-list div [role="gridcell"]:last-child div {
  width: 50%;
}

.cartpg.flt {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  text-align: center;
  background-color: rgb(255 255 255 / 90%);
  z-index: 9;
  display: flex;
  align-items: center;
  width: 100%;
  align-content: center;
  justify-content: center;
}

.no-data {
  text-align: center;
  padding: 2% 0;
  width: 100%;
}

.detail-sec-item.image,
.detail-sec-item.video {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* max-height: 200px; */
  /* padding: 10px 0px; */
  position: relative;
}

.detail-sec-item.video {
  /* display: block; */
}

.detail-sec-item.audio {
  /* display: flex; */
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* padding: 10px; */
  position: relative;
}

.audio-img-sec {
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.audio-img-sec img {
  width: 100%;
}

.vw-dtl-btn {
  display: none;
  position: absolute;
  color: #712527;
  font-family: MuktaMalarNormal;
  font-weight: 400;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #c98f2c;
  border-radius: 20px;
  padding: .2% 3%;
  text-transform: capitalize;
  /* z-index: 999; */
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.vw-dtl-btns {
  z-index: 1;
}

.vw-dtl-btn.show {
  display: block;
  opacity: 1;
  z-index: 1 !important;
}

.detail-sec-item.show img {
  background-color: rgb(255, 255, 255) !important;
  z-index: -1;
  opacity: 0.5;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  /* background: rgba(0, 255, 255, 0.5); */
  /* border-radius: 8px 8px 0px 0px; */
}

img.image-sec {
  width: 100%;
}

.detail-sec-item1.show video {
  background-color: rgb(255, 255, 255) !important;
  z-index: -1;
  opacity: 0.5;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  /* background: rgba(0, 255, 255, 0.5); */
  /* border-radius: 8px 8px 0px 0px; */
}

.detail-sec-item1 video {
  width: 100%;
}

.detail-sec-item1.show .overlay {
  position: relative;
  display: inline-block;
  /* background: rgba(113, 37, 39, 0.69); */
}

.video_main {
  z-index: -1;
}

.overlay {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.overlay::before {
  border-radius: 10px 10px 0px 0px;
}

.detail-sec-item.show .overlay {
  position: relative;
  display: inline-block;
  /* background: rgba(113, 37, 39, 0.69); */
}

.detail-sec-item.show .overlay>img {
  vertical-align: middle;
}

.detail-sec-item.show .overlay::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: 0.5s ease;
}

.detail-sec-item.show .overlay:hover::before {
  opacity: 0.5;
}

.modalPop {
  position: fixed;
  display: flex;
  width: 100%;
  z-index: 999999;
  height: 100%;
  background-color: transparent;
  align-content: center;
  align-items: center;
}

.list-outer-sec.inactive .menu-main,
.list-outer-sec.inactive.footer-main,
.list-main-sec.inactive {
  /* opacity: 0.6; */
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.modal-detail-head {
  color: #712527;
  font-size: 20px;
}

.list-main-sec {
  opacity: 1;
}

.pop-up-details-div {
  display: flex;
  height: 100%;
  padding: 3%;
}

.pop-up-close {
  border: none;
  background: transparent;
  box-shadow: none;
  font-size: 30px;
  padding: 0;
}

.close-modal-sec {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(201, 143, 44, 1);
  align-items: center;
  padding: 1% 3%;
}

#enquiryModal {
  background-color: rgba(0, 0, 0, 0.4);
}

#enquiryModal .modal-body {
  background-color: rgba(244, 230, 209, 1);
}

.css-1uccc91-singleValue {
  width: 100%;
}

.list-custom-class__option .option-sec {
  text-align: center;
  align-items: center;
  background-color: #f4e6d1;
  color: black;
  padding: 4%;
}

/* .detail-sec-item video {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  border-radius: 10px 10px 0px 0px;
  max-height: 210px;
} */

.detail-sec-item video {
  width: 100%;
  height: 210px;
  border-radius: 10px 10px 0px 0px;
}

.modal-dialog,
.modal-content {
  border-radius: 10px;
  border: 1px solid rgba(244, 230, 209, 1) !important;
}

.modal-body {
  padding: 0 !important;
}

.content-div {
  width: 60%;
  background-color: white !important;
  margin-left: 2%;
  border-radius: 8px;
  padding: 2%;
}

.image-div img {
  min-height: 230px;

  width: 100%;
}

.image-div {
  width: 40%;
  height: 100%;
  padding: 0.7% !important;
  align-items: center;
  display: block;
  background-color: white;
  border-radius: 8px;
  box-shadow: 3px 3px 3px 3px rgb(12 18 28 / 13%);
}

.hover_data {
  display: none;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  background-color: rgb(255 255 255);
  padding: 0px 10px;
  border-radius: 4px;
  font-size: 10px;
  box-shadow: 0px 0px 5px rgb(159 159 159 / 60%);
}

.image-data-name-sec:hover .hover_data {
  display: block !important;
}

.audio-img-sec-popup {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  text-align: center;
}

.audio-img-sec-popup audio {
  width: 100%;
}

.item-title {
  font-size: 18px !important;
}

.item-date,
.item-price {
  font-size: 16px !important;
}

.image-sec-list {
  border-radius: 8px !important;
  margin-right: 0 !important;
}

.footer-main.hide {
  background-color: rgba(0, 0, 0, 0.5);
}

.detail-sec-item audio {
  width: 100%;
  margin-top: 2%;
  padding: 0 1%;
}

.button_image button {
  background: #FFF6E8;
  border: 1px solid #712527;
  border-radius: 3px;
  font-family: 'Mukta Malar', sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #2A2E34;
  padding: 10px 16px;
  margin-right: 7px;

}

.button_image button img {
  margin-right: 6px;
}

.detail-sec-item1.video video {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.detail-sec-item1.video {
  position: relative;
  height: 210px;
}

.vw-dtl-btn1.vw-dtl-btns1 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}


.vw-dtl-btn1.vw-dtl-btns1 {
  display: none;
  position: absolute;
  color: #712527;
  font-family: MuktaMalarNormal;
  font-weight: 400;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #c98f2c;
  border-radius: 20px;
  padding: .2% 3%;
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
}

/* 
.detail-sec-item1.video::before:hover {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 210px;
  background-color: rgb(255, 247, 235);
  border-radius: 10px 10px 0px 0px;
  z-index: 1;
} */

/* .detail-sec-item1.video:hover {
  background-color: rgb(255 0 0);
  border-radius: 10px 10px 0px 0px;
opacity: 0.5;
} */


.detail-sec-item1.video:hover img.image-sec {
  cursor: pointer;
  position: relative;
  /* z-index: -1; */
  opacity: 0.5;
}

.detail-sec-item1.video:hover video.image-sec {
  cursor: pointer;
  position: relative;
  z-index: -1;
}

.detail-sec-item1.video:hover .vw-dtl-btn1.vw-dtl-btns1 {
  display: block;
  z-index: 1 !important;
}



@media (min-width: 320px) and (max-width: 479px) {
  .video_mobile {
    width: 100%;
  }
  .image-div-list audio {
    width: 100%;
  }

  .pop-up-details-div {
    display: block;
    height: 100%;
  }

  .css-2b097c-container {
    position: relative;
    box-sizing: border-box;
    /* margin: 14px 0px; */
  }

  .pagination li a {
    /* width: 0px; */
  }

  .pagination {
    justify-content: center;
  }

  .detail-sec-item {
    display: block;
    max-height: none;
  }

  .display_price {
    display: block;
  }

  .image-data-name {
    width: 100%;
    white-space: normal;
    white-space: initial;
    overflow: visible;
    text-overflow: initial
  }

  .image-data-count-sec {
    width: 100%;
    text-align: left;
    margin: 5px 0px;
  }
}

.audio_main {
  z-index: 1;
  text-align: center;
}



@media (min-width: 320px) and (max-width: 479px) {

  .image-sec {
    width: 100%;
  }

  .add-to-cart {
    padding: 0px;
  }

  .rm-cart-button {
    padding: 0px;
  }

  .tab button {
    padding: 2%;
  }

  video {
    max-height: 200px;
    /* z-index: -1; */
  }

  .image-div img {
    min-height: 230px;
    max-height: 250px;
    width: 100%;
  }

  .audio_main {
    z-index: -1;
  }

  /* 
  .detail-sec-item.video video {
    height: 100%;
  }

    .detail-sec-item.video video {
    z-index: 1;
}

  .detail-sec-item.video.show video {
    z-index: 1;
}

.vw-dtl-btn.show {
    display: block;
    opacity: 1;
    z-index: 1 !important;
} */

}

@media (max-width: 767px) {
  .search_main .col-sm-4 {
    padding-right: 10px;
  }

  .src_icon {
    top: 10px;
  }


  .search_main .col-sm-8 {
    padding-left: 10px;
  }

  .search_left button:last-child {
    margin-right: 0px;
  }

  .tab button {
    font-size: 15px;
  }

  .search_left button {
    font-size: 12px;
  }

  .search_right input {
    font-size: 12px;
    padding: 10px 15px;
  }

  /* .detail-sec-item.video.show .detail-sec-item.video video {
  z-index: 1;
} */

  .detail-sec-item.video video {
    /* z-index: 1; */
    height: 100% !important;
  }

}


@media (min-width: 480px) and (max-width: 639px) {
  .display_price {
    display: block;
  }

  .image-data-name {
    width: 100%;
    white-space: normal;
    white-space: initial;
    overflow: visible;
    text-overflow: initial
  }

  .image-data-count-sec {
    width: 100%;
    text-align: left;
    margin: 5px 0px;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .display_price {
    display: block;
  }

  .image-data-name {
    font-size: 15px;
    width: 100%;
    white-space: normal;
    white-space: initial;
    overflow: visible;
    text-overflow: initial;
  }

  .image-data-count-sec {
    width: 100%;
    text-align: left;
    margin: 5px 0px;
  }

  .image-data-count {
    font-size: 13px;
  }

  .image-data-amount {
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .src_icon {
    top: 10px;
  }

  .search_main .col-sm-4 {
    padding-right: 15px;
    width: 100%;
  }

  .search_main .col-sm-8 {
    padding-left: 10px;
    width: 100%;
  }

  .search_left button:last-child {
    margin-right: 0px;
  }

  .display_price {
    display: block;
  }
  .image-data-name {
    font-size: 15px;
    width: 100%;
    white-space: normal;
    white-space: initial;
    overflow: visible;
    text-overflow: initial;
  }
  .image-data-count-sec {
    width: 100%;
    text-align: left;
    margin: 5px 0px;
  }
  .image-data-count {
    font-size: 13px;
  }
  .image-data-amount {
    font-size: 13px;
  }
}

.data_display {
  padding: 18px 0px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .display_price {
    display: block;
  }
  .image-data-name {
    font-size: 15px;
    width: 100%;
    white-space: normal;
    white-space: initial;
    overflow: visible;
    text-overflow: initial;
  }
  .image-data-count-sec {
    width: 100%;
    text-align: left;
    margin: 5px 0px;
  }
  .image-data-count {
    font-size: 13px;
  }
  .image-data-amount {
    font-size: 13px;
  }
}
.image-div-cart .image-sec-list {
  width: 100%;
  margin-right: 2%;
  max-height: 210px;

}

.get-cart-div {
  padding: 8%;
}

.cart-sectn {
  display: flex;
  background-color: rgba(234, 220, 199, 1);
  width: 100%;
  flex-direction: column;
  padding: 2% 4%;
  border-radius: 8px;
  min-height: calc(100vh - 208px);
}

.main-div-cart {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 1% 0;
  /* justify-content: space-between; */

}

.image-div-cart {
  align-items: center;
  width: 30% !important;
  display: flex;
  background-color: white;
  border-radius: 8px;
  padding: 0.5%;
  display: flex;
  flex-direction: column;
  box-shadow: 3px 3px 3px 3px rgb(12 18 28 / 13%);
}

.image-div-cart video {
  width: 100%;
}

.content-div-cart {
  width: 50%;
  background-color: rgba(244, 230, 209, 1);
  margin-left: 2%;
  border-radius: 8px;
  padding: 2%;
  /* box-shadow: 3px 3px 3px 3px rgb(12 18 28 / 10%); */
}

.item-title {
  font-family: MuktaMalarMedium;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0.5%;
}

.item-title span {
  font-size: 18px;
  text-transform: capitalize;
}

.item-price {
  font-family: MuktaMalarMedium;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0.5%;
}

.item-date {
  font-family: MuktaMalarMedium;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0.5%;
}

.button-sec {
  width: 100%;
  padding: 2px 38px;
  text-align: right;
}

.checkout-button {
  background-color: rgba(241, 187, 95, 1);
  border-color: rgba(113, 37, 39, 1);
  font-family: MuktaMalarBold;
  border-width: 0.5px;
  padding: 1% 1.5%;
  border-radius: 20px;
  color: white;
  box-shadow: none;
  font-size: 16px;
  border-style: solid;
}

.checkout-button:hover {
  background-color: rgba(113, 37, 39, 1);
}

.proceed_checkout {
  text-align: right;
  padding: 24px 235px;
}

.rm-cart-button {
  background-color: transparent;
  border-color: rgba(118, 118, 118, 1);
  font-family: MuktaMalarBold;
  border-width: 0.5px;
  padding: 5px 7px;
  border-radius: 20px;
  color: rgba(118, 118, 118, 1);
  box-shadow: none;
  font-size: 16px;
  border-style: solid;
  width: 100%;
}

.rm-cart-buttons {
  background-color: transparent;
  border-color: rgba(118, 118, 118, 1);
  font-family: MuktaMalarBold;
  border-width: 0.5px;
  padding: 5px 7px;
  border-radius: 20px;
  color: rgba(118, 118, 118, 1);
  box-shadow: none;
  font-size: 16px;
  border-style: solid;
}

.rm-cart-button:hover {
  background-color: rgba(234, 220, 199, 1);
}

@media (max-width: 767px) {
  .space_around {
    padding: 10px 16px;
  }

  .main-div-cart {
    flex-direction: column;
  }

  .content-div-cart,
  .image-div-cart,
  .button-sec {
    width: 100%;
    display: flex;
    flex-direction: column;

  }

  .button-sec {
    padding: 2%;

  }

  .content-div-cart {
    margin: 0;
    text-align: center;
  }

  .checkout-button {
    width: 100%;
    margin: 0px;
  }

  .proceed_checkout {
    padding: 24px;
    text-align: center;
  }

  .image-sec-list-cart {
    max-height: 100%;
  }
}

@media (max-width: 767px) {

  .image-div-cart {
    /* min-height: 100px;
    max-height: 100%; */
    margin-bottom: 2%;
    padding: 3%;
    width: 100% !important;
  }

  .item-title {
    font-size: 14px !important;
    margin-bottom: 5px !important;
  }

  .item-title span {
    font-size: 14px;
  }

  .item-date,
  .item-price {
    font-size: 12px;
  }

  .cart-sectn .main-div-cart {
    margin-bottom: 15px;
  }

}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .button-sec {
    width: 90%;
  }

  .content-div-cart {
    width: 68%;
  }
}

.audio {
  width: 100%;
  /* height: 29%; */
}

.table-image {
  width: 100%;
  max-height: 500px;
}

.empty_main.flt {
  text-align: center;
}

.empty_section {
  font-family: 'Mukta Malar', sans-serif;
  font-size: 31px;
  font-weight: 500;
  color: rgba(113, 37, 39, 1);
  text-align: center;
}

.empty_sec {
  font-family: 'Mukta Malar', sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: rgba(201, 143, 44, 1);
  text-align: center;
}

.empty_button {
  text-align: center;
  margin-top: 25px;
}

.empty_button button {
  background-color: rgba(241, 187, 95, 1);
  border: 1px solid rgba(113, 37, 39, 1);
  border-radius: 30px;
  padding: 5px 61px;
}


@media (min-width: 320px) and (max-width: 479px) {
  .video_main1 {
    z-index: 1 !important;
  }

  .proceed_checkout {
    padding: 24px 15px;
  }

}

.content-div-cart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


@media (min-width: 768px) and (max-width: 991px) {
  .proceed_checkout {
    text-align: right;
    padding: 24px 110px;
  }

  .proceed_checkout .checkout-button {
    padding: 5px 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .proceed_checkout {
    text-align: right;
    padding: 24px 0px;
  }
}

@media (min-width: 1200px) and (max-width: 1365px) {
  .proceed_checkout {
    text-align: right;
    padding: 24px 0px;
  }
}
.flt {
    width: 100%;
    float: left;
}
.event-main {
    min-height: calc(100vh - 435px);
}
.main-page {
    background-color: #f4e6d1;
}

.logsign-btton {
    display: flex;
    padding: 10px 0px;
}
.blog-main {
    background-color: #FFF7EB;
}

.event-img img{
    width: 100%;
}

.event-maindiv {
    padding: 3%;
}

.event-text {
    padding: 3% 0;
    font-size: 28px;
    font-weight: bold;
    color: #712527;
    line-height: 41px;
}

.blog-divdr hr{
    margin: 0;
}

.event-date {
    font-family: Spectral;
    font-size: 12px;
    font-weight: 600;
    color: #712527;
    line-height: 10px;
    margin: 5% 0;
}

.event-headtxt {
    font-family: Spectral;
    font-size: 17px;
    font-weight: 500;
    color: #1b1a19;
    margin: 1% 0;
}

.event-contnttxt {
    font-family: Spectral;
    font-size: 14px;
    font-weight: 400;
    color: #7e7e7e;
    margin: 2% 0;
    
}

.event-main {
    margin: 45px 0px;
    background-color: #FFF6E8;
}

.event-maindiv {
    margin-bottom: 2%;
    background-color: #fefefe;
    box-shadow: 5px 4px 3px 1px rgb(12 18 28 / 12%);

}
@media (max-width: 768px) {
   
}
/* @import url('https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap'); */

.flt {
    width: 100%;
    float: left;
}

.main-page {
    background-color: #FFF6E8;
}

.cart-div {
    text-align: center;
}

.blog-div {   
    margin: 3% 0 1% 0;
}

.blog-div .container{
    background-color: #fefefe;
    padding: 1%;
}

.blog-hr hr {
    width: 50px;
    height: 2px;
    color: #712527;
}

.blog-text {
    padding: 28px 0px;
    font-size: 28px;
    font-weight: bold;
    color: #712527;
    line-height: 41px;
}

.blog-date {
    font-family: 'Mukta Mahee', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #c98f2c;
    letter-spacing: 3px;
    text-transform: uppercase;
    padding: 0 5%;
    margin-bottom: 2%;
}

.blog-textdiv {
    padding: 20px 0px;
}

.blogimg {
    position: relative;
}

.blogimg img {
    width: 100%;
}

.blg-brdr {
    border: 1px solid #712527;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    position: absolute;
    top: 126px;
    background-color: #712527;
    width: 126px;
}

.blog-txthead {
    font-family: 'Spectral', serif;
    font-size: 20px;
    font-weight: 500;
    color: #712527;
    padding: 4px 0px;
    padding: 0 5%;
    margin-bottom: 2%;
}

.blog-txtcontnt {
    font-family: 'Spectral', serif;
    font-size: 16px;
    font-weight: 400;
    color: #712527;    
    padding: 0 5%;
}

.blog-cnt-sec{
    border-left: 1.5px solid #712527;
    display: flex;
    flex-direction: column;
}

.blog-bttn {
    font-family: Spectral;
    font-weight: 400;
    color: #f4e6d1;
    margin: 3% 0;
    padding: 0 5%;
}

.blog-bttn button {
    background-color: #712527;
    border: none;
    color: #f4e6d1;
    font-size: 14px;
    font-weight: 400;
    line-height: 13px;
    padding: 2% 5%;
    font-family: Spectral;
    letter-spacing: 4px;
}

.blog-sec-content{
    margin: 5% 0;
}

hr {
    border: 1px solid #712527 !important;
}

@media (max-width: 767px) {
    .blog-div {
        margin: 4% 0;
        padding: 0 4%;
    }
    .blog-div .container{
        padding: 3%;
    }
    .blog-textdiv{
        text-align: center;
    }
    .blog-cnt-sec{
        display: block;
        border: none;
    }
}
.flt {
  float: left;
  width: 100%;
}

.displayproperty {
  visibility: hidden;
}

.cart-count {
  font-size: 10px;
  background-color: white;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #c98f2c;
}

.cart-sec {
  display: flex;
}

.chckot-maintxt-orders {
  background-color: #eadcc7;
  padding: 2%;
}

.chckot-maintxt {
  display: flex;
  background-color: #eadcc7;
  padding: 2%;
}

.chcekout-maindiv {
  padding: 42px 0px;
}

.chkot-disply {
  display: flex;
  margin: 27px 0px;
}

.checkout-div {
  border-right: 1px solid #dc8b00;
  background-color: #fffaf2;
  padding: 2%;
  border-radius: 10px 0px 0px 10px;
}

.checkout-secdiv {
  background-color: #fffaf2;
  padding: 2%;
  border-radius: 0px 10px 10px 0px;
}

.chckot {
  font-family: MuktaMalarMedium;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
}

.chekout-account {
  font-family: MuktaMalarMedium;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  padding: 0px 0px 28px 0px;
}

.left-div {
  float: left;
  width: 50%;
  border: 1px solid rgba(242, 204, 156, 1);
  background-color: rgba(255, 255, 255, 1);
}

.right-div {
  width: 50%;
  padding: 2% 5%;
}

.sign-inbuttn {
  padding-bottom: 24px;
}

.sign-inbuttn a {
  font-family: MuktaMalarMedium;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 30px;
  padding: 1% 5%;
  background-color: #f1bb5f;
  border: 1px solid #dc8b00;
  text-decoration: none;
}

.sign-inbuttn a:hover {
  color: #712527;
}

.order-detail-main-sec {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgba(255, 250, 242, 1);
  padding: 2%;
  border-radius: 8px;
  margin-top: 4%;
}

.sub-sec-div {
  display: flex;
}

.order-haed {
  color: rgba(113, 37, 39, 1);
  font-family: MuktaMalarMedium;
  font-size: 16px;
  font-weight: 500;
  padding: 4%;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(242, 204, 156, 1);
  flex-direction: column;
  display: flex;
  margin-bottom: 1%;
}

.order-haed-billing {
  color: rgba(113, 37, 39, 1);
  font-family: MuktaMalarMedium;
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  flex-direction: column;
  /* display: flex; */
  margin-bottom: 1%;
}

.order-details-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 9px;
}

.order-details-image {
  width: 30%;
  /* padding: 1%; */
  /* box-shadow: 2px 1px 5px 2px rgb(12 18 28 / 13%); */
  /* display: flex; */
  flex-direction: column;
  max-height: 100%;
}

.order-details-image.audios {
  width: auto;
}

.order-details-image.videos {
  width: 60%;
}

.order-details-image video {
  width: 100%;
  height: 100%;
}

.order-details-image audio {
  height: 30px;
  margin-top: 10px;
}

.order-details-data {
  width: 70%;
  padding: 0 4%;
}

.order-details-divider {
  /* padding: 2% 4%;
  width: 100%; */
}

.order-details-divider hr,
.order-calc-tot hr {
  margin: 0;
  position: relative;
  height: 1px !important;
  border: none !important;
  color: rgba(242, 204, 156, 1);
}

.order-details-prod-name {
  color: #000000;
  font-family: MuktaMalarMedium;
  font-size: 16px;
  font-weight: 500;
}

.order-details-prod-count {
  font-size: 16px;
  font-family: MuktaMalarMedium;
}

.order-details-prod-count {
  font-size: 12px;
  font-family: MuktaMalarMedium;
}

.order-item-price {
  font-size: 12px;
  font-family: MuktaMalarMedium;
}

.order-calc-sec {
  padding: 2% 4%;
}

.order-calc-tot {
  padding: 0 4%;
  margin-bottom: 3%;
}

.order-calc-det {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-calc-head {
  font-size: 16px;
  font-family: MuktaMalarMedium;
  font-weight: 400;
  margin: 2% 0;
}

.order-calc-cont {
  font-size: 16px;
  font-family: MuktaMalarMedium;
  font-weight: 400;
  margin: 2% 0;
}

.order-details-image img {
  height: 68px;
  width: 100px;
  box-shadow: 2px 1px 5px 2px rgb(12 18 28 / 13%);
}

.order-calc-tot-head,
.order-calc-tot-cont {
  font-size: 22px;
  font-family: MuktaMalarMedium;
  font-weight: 400;
  margin: 2% 0;
}

.order-calc-tot-head1,
.order-calc-tot-cont1 {
  font-family: "Mukta Malar", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.cust-info-sec {
  border: 1.5px solid rgba(96, 31, 33, 1);
  width: 100%;
  border-radius: 6px;
  padding: 2%;
}

.cust-info-head,
.cust-info-cont,
.cust-info-head-sep {
  font-size: 16px;
  font-family: "MuktaMalarMedium", sans-serif;
  font-weight: 400;
  margin: 2% 0;
}

.cust-info-head {
  width: 45%;
  text-transform: uppercase;
}

.cust-info-cont {
  word-break: break-all;
  width: 50%;
  text-transform: capitalize;
}

.email_text {
  text-transform: lowercase !important;
}

.cust-info-det {
  display: flex;
  justify-content: space-between;
}

.pay-button {
  background-color: rgba(241, 187, 95, 1);
  border: 1px solid rgba(113, 37, 39, 1);
  border-radius: 30px;
  padding: 1% 4%;
  width: 25%;
  color: white;
  font-family: MuktaMalarMedium;
  font-size: 18px;
}

.pay-btn-sec {
  margin-top: 2%;
  padding: 0 2%;
}

.purpose {
  padding: 20px 0px;
}

.purposedata div {
  color: rgba(113, 37, 39, 1);
  font-family: MuktaMalarMedium;
  font-size: 16px;
  font-weight: 500;
}

.purposedata span {
  padding: 5px 15px 12px 0px;
}

.selectpurpose {
  margin-left: 2px;
}

.cover_purpose {
  margin-top: 10px;
}

.cover_purpose span {
  display: flex;
  align-items: center;
  float: left;
  width: auto;
}

.cover_purpose span input {
  margin-right: 5px;
}

@media (max-width: 768px) {
  .chkot-disply {
    flex-direction: column;
  }

  .checkout-div {
    border-radius: 10px;
    border-right: none;
    padding: 4%;
    text-align: center;
  }

  .checkout-secdiv {
    border-radius: 10px;
    margin: 2% 0;
    padding: 4%;
    text-align: center;
  }

  .chckot-maintxt,
  .chckot-maintxt-orders {
    padding: 4%;
  }

  .order-detail-main-sec,
  .order-details-div {
    flex-direction: column;
    padding: 30px 9px;
  }

  .left-div,
  .right-div,
  .order-details-image,
  .order-details-data {
    width: 100%;
  }

  .right-div {
    padding: 2%;
    margin-top: 4%;
  }

  .order-details-prod-name {
    text-align: center;
    margin-bottom: 1%;
  }

  .order-item-price {
    text-align: center;
    margin-bottom: 1%;
    font-size: 14px;
  }

  .order-details-data {
    margin-top: 4%;
  }

  .order-haed,
  .order-haed-billing {
    text-align: center;
  }

  .cust-info-det {
    justify-content: center;
  }

  .cust-info-head,
  .cust-info-cont {
    width: auto;
  }

  .cust-info-head-sep {
    margin: 2%;
  }

  .cust-info-sec {
    width: 100%;
  }

  .order-detail-main-sec {
    margin-top: 14%;
  }

  .image-div-cart audio {
    height: 60px !important;
  }

  .table-image {
    max-height: 100% !important;
  }

  .video-tag {
    width: 100%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .right-div {
    width: 50%;
    padding: 2% 2%;
  }

  .left-div {
    width: 50%;
  }

  .cust-info-sec {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .sub-sec-div {
    display: block;
  }

  .order-details-image img {
    width: 100%;
    height: auto;
  }

  .pay-btn-sec {
    text-align: center;
  }

  .purposedata span {
    font-size: 13px;
  }

  .order-details-image img {
    width: 100% !important;
  }

  .order-details-image img {
    height: 174px;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .sub-sec-div {
    display: block;
  }

  .order-details-image img {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .sub-sec-div {
    display: block;
  }

  .order-details-image img {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sub-sec-div {
    display: block;
  }

  .order-details-image img {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 769px) {
  .image-div-cart audio {
    height: 60px !important;
  }

  .order-details-image img {
    width: 100%;
  }
}

/* .sub-sec-div */

.flt{
    float: left;
    width: 100%;
}
.thank_main {
    text-align: center;
}
.thankyou_img {
    min-height: calc(100vh - 208px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.thankyou_text{
font-family: 'Mukta Malar',sans-serif;
font-size: 31px;
font-weight: 500;
color: rgba(113, 37, 39, 1);
}
.thankyou_text1{
font-family: 'Mukta Malar',sans-serif;
font-size: 20px;
font-weight: 500;
color: rgba(201, 143, 44, 1);
}
.flt {
  float: left;
  width: 100%;
}
.contactus_main {
  background-color: #fff6e8;
  padding-top: 30px;
}
.contact_details {
  font-family: "Mukta Malar";
  font-size: 24px;
  font-weight: 400;
  color: rgba(113, 37, 39, 1);
  text-align: center;
  letter-spacing: 1px;
  padding: 10px 0px;
}
.contact_details span {
  position: relative;
}
.contact_details span::before {
  content: "";
  position: absolute;
  top: 18px;
  left: -72px;
  height: 3px;
  width: 65px;
  background-color: rgba(201, 143, 44, 1);
}
.contact_details span::after {
  content: "";
  position: absolute;
  width: 65px;
  height: 3px;
  top: 18px;
  right: -72px;
  background-color: rgba(201, 143, 44, 1);
}
.contact_content {
  font-family: "Butler";
  font-size: 19px;
  font-weight: 300;
  color: rgba(113, 37, 39, 1);
  text-align: center;
  padding: 30px 0px;
}
.main_content_box {
  font-family: "Butler";
  font-size: 14px;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  min-height: 100px;
  padding: 26px;
  border: 1px solid rgba(201, 143, 44, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 202px;
}
.icon_main {
  /* border: 1px solid rgba(201, 143, 44, 1); */
  margin-bottom: -20px;
}
.icon_main span {
  border: 1px solid rgba(201, 143, 44, 1);
  padding: 5px;
  display: inline-block;
  background-color: #f4e6d1;
}
.main_box {
  text-align: center;
}
.map_box {
  width: 100%;
  background-image: url(/static/media/contact_bg.cfb29e76.png);
  background-repeat: no-repeat;
  padding: 35px 80px;
  background-color: rgba(255, 255, 255, 1);
  margin-top: 54px;
  background-size: cover;
}
.google_map {
  width: 600px;
  height: 450px;
  border: 0px;
}
.map_box iframe {
  display: block !important;
  border-radius: 6px;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);
}
@media (min-width: 320px) and (max-width: 479px) {
  .map_box {
    padding: 35px 12px !important;
  }
  .contact_details span::after {
    width: 59px;
    right: -59px;
  }
  .contact_details span::before {
    left: -59px;
    width: 57px;
  }
  .icon_main span {
    margin-top: 14px;
  }
  .main_box {
    margin-bottom: 20px;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .main_box {
    margin-bottom: 20px;
  }
}
@media (min-width: 640px) and (max-width: 767px) {
  .main_content_box {
    min-height: 120px;
    padding: 26px 5px;
    word-break: break-word;
  }
}

.myaccount_main{
    padding: 70px;
        min-height: calc(100vh - 208px);
}
.accountList{
    padding: 8%;
}
.accountList li {
    list-style-type: none;
    float: left;
    width: 100%;
    margin-bottom: 2px;
    background-color: rgba(255, 245, 231, 1);

}
.accountList li a.active {
    background-color: rgba(241, 187, 95, 1);
    font-family: 'Mukta Malar';
    font-size: 12px;
    font-weight: 400;
    color: rgba(113, 37, 39, 1);
}
.myacc{
    padding:16px;
}
.accountList li a {
    letter-spacing: 0;
    line-height: 17px;
    text-decoration: none;
    padding: 16px;
    float: left;
    width: 100%;
    background-color: #f7f7f7;
    font-family: 'Mukta Malar';
font-size: 12px;
font-weight: 400;
color: rgba(113, 37, 39, 1);
}
.accountrightmenu{
    font-family: 'Mukta Malar',sans-serif;
font-size: 20px;
font-weight: 400;
color: rgba(0, 0, 0, 1);
line-height: 15px;
}
.acc_detail {
    background-color: rgba(255, 245, 231, 1);
    border-radius: 6px;
    padding: 14px;
    font-family: 'Mukta Malar',sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
}
.acc_holder {
    padding: 18px 0px;
}
.accsub_detail{
    background-color: rgba(250, 237, 218, 1);
}
.subdetail li{
    list-style: none;
}
.subdetail li a{
    font-family: 'Mukta Malar';
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);

}
ul.subdetail li {
    padding: 3px 0px;
}
li.subdtl a {
    font-family: 'Mukta Malar';
    font-size: 20px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
}
.user_detail tbody tr:nth-child(odd){
    background-color: rgba(250, 237, 218, 1);
}
.user_detail th {
    
    font-family: 'Mukta Malar';
font-size: 12px;
font-weight: 400;
color: rgba(113, 37, 39, 1);
background-color: rgba(250, 237, 218, 1);
padding: 6px 0px;
text-align: center;
width: 106px;
}
/* .user_detail tbody{

} */
.user_detail tbody td{
    text-align: center;
        font-family: 'Mukta Malar',sans-serif;
font-size: 12px;
font-weight: 400;
color: rgba(0, 0, 0, 1);
padding: 10px 0px;
}
.main_table {
    padding: 24px 0px;
  
}
.my_order{
    font-family: 'Mukta Malar';
    font-size: 20px;
    font-weight: 400;
    color: rgba(113, 37, 39, 1);
    padding: 10px 24px;
    background-color: rgba(255, 245, 231, 1);
}
@media (max-width: 767px) {
  .myaccount_main{
        padding: 20px;
    }
    .acc_holder {
    padding: 18px 10px;
}
.my_order{
        font-size: 16px;

    padding: 16px 14px;

}
.user_detail th{
    padding: 10px 10px;
}
.map_box{
        padding: 35px 122px;
}
.container_header_main{
    padding: 0px !important;
}
}
@media (min-width: 320px) and (max-width: 479px) {
    .accountList {
        padding: 3%;
    }
}
@media (min-width: 480px) and (max-width: 639px) {
    .map_box {
        padding: 35px 12px;
    }
    .accountList {
        padding: 3%;
    }
}
@media (min-width: 640px) and (max-width: 767px) {
    .myacc {
        padding: 5px;
    }
    .myacc img {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .myacc {
        padding: 5px;
    }
    .myacc img {
        width: 100%;
    }
}
@media  (min-width: 768px) and (max-width: 1366px) {
  .map_box{
      padding: 35px 106px;
  }
  .cont_box{
      padding: 0px 4px;
  }
}


@font-face {
    font-family: 'MuktaMalarBold';
    src: url(/static/media/MuktaMalar-Bold.6ba1b849.ttf) format("opentype");
}

@font-face {
    font-family: 'MuktaMalarNormal';
    src: url(/static/media/MuktaMalar-Regular.41691876.ttf) format("opentype");
}

@font-face {
    font-family: 'MuktaMalarMedium';
    src: url(/static/media/MuktaMalar-Medium.e268cd38.ttf) format("opentype");
}

@font-face {
    font-family: 'MuktaMalarLight';
    src: url(/static/media/MuktaMalar-Light.0ed14377.ttf) format("opentype");
}

@font-face {
    font-family: 'AguafinaScript';
    src: url(/static/media/AguafinaScript-Regular.db13182e.ttf) format("opentype");
}

@font-face {
    font-family: 'Spectral';
    src: url(/static/media/Spectral-Medium.541f011f.ttf) format("opentype");
}

@font-face {
    font-family: 'ButlerNormal';
    src: url(/static/media/Butler_Regular.b85860db.otf) format("opentype");
}

@font-face {
    font-family: 'ButlerMedium';
    src: url(/static/media/Butler_Medium.d5d598fd.otf) format("opentype");
}

iframe{
    z-index: 0 !important;
    display: none;
}

body{
    background-color: rgb(255, 247, 235);
}
.bottm-footer a {
    color: #601f21;
    text-decoration: none;
}
.container_header_main{
        background-color: #FFE9C2;
    padding: 0px 34px;
    border-bottom: 1px solid #712527;
}
/* .bMgaAx div:first-child{
    text-align: center;
} */
.menuH_container nav {
  width: 100%;
  margin: 0 auto;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.menuH_container nav .megamenu_panel button {
  padding: 15px 30px;
  text-align: center;
  font-family: 'FuturaNormal';
  font-size: 13px;
  font-weight: 500;
  color: rgba(4, 4, 4, 1);
  letter-spacing: 0px;
  line-height: 17px;
  text-decoration: none;
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  width: auto;
  letter-spacing: 0.5px;
}

.menuH_container nav .megamenu_panel button span {
  font-size: 12px;
  display: none;
}

.menuH_container .header {
  display: none;
}



@media(min-width: 768px) {
  .megamenu_list {
    display: none;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 46px;
    width: 100%;
    margin: 0;
    background: rgb(255, 255, 255);
    box-shadow: 0px 3px 6px rgb(179 179 179 / 50%);
  }

  .megamenu_panel:hover .megamenu_list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
}



.menuH_container .row {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}


.menuH_container .column {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-right: 1px solid rgb(241 241 241);
  padding: 15px 0px;
}

.menuH_container .column:last-child {
  border-right: none;
}

.menuH_container .column a {
  font-family: 'FuturaNormal';
  font-size: 13px;
  font-weight: 500;
  color: rgba(4, 4, 4, 1);
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.menuH_container .column a:not(last-child) {
  padding: 15px 40px;
}


.menuH_container .hamburger {
  /* width: 35px;
    height: 25px; */
  width: 25px;
  height: 16px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  display: none;
}

.menuH_container .hamburger:hover {
  cursor: pointer;
}

.menuH_container .hamburger .bar {
  width: 100%;
  height: 2px;
  background: #333;
  border-radius: 5px;
}

.menuH_container .rotate {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  background: -webkit-linear-gradient(0deg, rgba(181, 126, 16, 1) 60%, rgba(221, 183, 78, 1) 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 767px) {
  .menuH_container .header {
    display: flex;
    justify-content: space-between;
  }

  .menuH_container .hamburger {
    display: flex;
  }

  .menuH_container nav {
    width: 50%;
    flex-direction: column;
    height: 100%;
    padding: unset;
    margin: unset;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    /* display: none; */
  }

  .displaynavdata {
    width: 50%;
    flex-direction: column;
    height: 100%;
    padding: unset;
    margin: unset;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    display: flex !important;
    transition-timing-function: ease-in;
  }

  .displaynavdata1 {
    width: 50%;
    flex-direction: column;
    height: 100%;
    padding: unset;
    margin: unset;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    display: none !important;
    transition-timing-function: ease-in;
  }

  .menuH_container nav .megamenu_panel button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menuH_container nav .megamenu_panel button span {
    display: inline;
  }

  .menuH_container nav .megamenu_panel button:hover {
    color: black;
    text-decoration: none;
    border-bottom-color: #eee;
    cursor: unset;
  }

  /* .menuH_container nav .megamenu_panel:hover .megamenu_list {
    display: none;
  } */

  .megamenu_list {
    /* display: none; */
    position: unset;
    width: 100%;
    padding: unset;
    flex-direction: column;
  }

  .menuH_container .row {
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .menuH_container .column {
    padding: 0 20px;
    margin: 0 0 10px 0;
  }
}

@media (max-width: 600px) {
  .menuH_container nav {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .menuH_container nav {
    top: 52px;
    height: calc(100vh - 52px);
  }

  .header_logo {
    width: 60%;
  }

  .menuH_container .header {
    top: 20px;
  }

  .mobile_header {
    margin-top: 50px;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .menuH_container nav {
    top: 72px;
    height: calc(100vh - 72px);
  }

  .header_logo {
    width: 85%;
  }

  .menuH_container .header {
    top: 28px;
  }

  .mobile_header {
    margin-top: 70px;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .menuH_container nav {
    top: 95px;
    height: calc(100vh - 95px);
  }

  .header_logo {
    width: 80%;
  }

  .menuH_container .header {
    top: 38px;
  }

  .mobile_header {
    margin-top: 90px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .menuH_container .column a:not(last-child) {
    padding: 15px 25px;
  }
}

@media (max-width: 767px) {
  .menuH_container .header {
    padding-left: 20px;
    width: 45px;
    position: fixed;
    left: 0px;
    /* z-index: 9;
    position: relative; */
  }

  /* .site_nav {
    position: fixed;
    background-color: rgb(255, 255, 255);
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 9;
  } */

  .megamenu_list {
    left: 0px !important;
    -webkit-transform: translateX(0%) !important;
            transform: translateX(0%) !important;
  }

  .megamenu_panel {
    width: 100%;
  }

  .megamenu_list .column {
    border-right: none;
    width: 100%;
  }

  .menuH_container .column a {
    border-bottom: 1px solid rgb(235 235 235);
  }

  .menuH_container nav {
    position: fixed;
    background-color: rgb(255, 255, 255);
    left: 0px;
    width: 100%;
    overflow: auto;
  }

  .menuH_container nav .megamenu_panel:first-child {
    border-top: 1px solid rgba(211, 211, 211, 0.2);
  }

  .menuH_container nav .megamenu_panel button {
    border-bottom: 1px solid rgb(247 246 250);
  }
}

/* Animation for hamburger menu */
.animInfo {
  -webkit-animation-duration: .5s;
          animation-duration: .5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
}

@-webkit-keyframes bar1Anim {
  0% {
    -webkit-transform: rotate(0deg) translate(0px);
            transform: rotate(0deg) translate(0px);
  }

  /* 100%{transform: rotate(45deg) translate(6px, 10px);} */
  100% {
    -webkit-transform: rotate(45deg) translate(3px, 8px);
            transform: rotate(45deg) translate(3px, 8px);
  }
}

@keyframes bar1Anim {
  0% {
    -webkit-transform: rotate(0deg) translate(0px);
            transform: rotate(0deg) translate(0px);
  }

  /* 100%{transform: rotate(45deg) translate(6px, 10px);} */
  100% {
    -webkit-transform: rotate(45deg) translate(3px, 8px);
            transform: rotate(45deg) translate(3px, 8px);
  }
}

@-webkit-keyframes bar2Anim {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes bar2Anim {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes bar3Anim {
  0% {
    -webkit-transform: rotate(0deg) translate(0px);
            transform: rotate(0deg) translate(0px);
  }

  /* 100%{transform: rotate(-45deg) translate(6px, -10px);} */
  100% {
    -webkit-transform: rotate(-45deg) translate(3px, -6px);
            transform: rotate(-45deg) translate(3px, -6px);
  }
}

@keyframes bar3Anim {
  0% {
    -webkit-transform: rotate(0deg) translate(0px);
            transform: rotate(0deg) translate(0px);
  }

  /* 100%{transform: rotate(-45deg) translate(6px, -10px);} */
  100% {
    -webkit-transform: rotate(-45deg) translate(3px, -6px);
            transform: rotate(-45deg) translate(3px, -6px);
  }
}

@-webkit-keyframes bar1AnimReverse {

  /* 0%{transform: rotate(45deg) translate(6px, 10px);} */
  0% {
    -webkit-transform: rotate(45deg) translate(3px, 8px);
            transform: rotate(45deg) translate(3px, 8px);
  }

  100% {
    -webkit-transform: rotate(0deg) translate(0px);
            transform: rotate(0deg) translate(0px);
  }
}

@keyframes bar1AnimReverse {

  /* 0%{transform: rotate(45deg) translate(6px, 10px);} */
  0% {
    -webkit-transform: rotate(45deg) translate(3px, 8px);
            transform: rotate(45deg) translate(3px, 8px);
  }

  100% {
    -webkit-transform: rotate(0deg) translate(0px);
            transform: rotate(0deg) translate(0px);
  }
}

@-webkit-keyframes bar2AnimReverse {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bar2AnimReverse {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes bar3AnimReverse {

  /* 0%{transform: rotate(-45deg) translate(6px, -10px);} */
  0% {
    -webkit-transform: rotate(-45deg) translate(3px, -6px);
            transform: rotate(-45deg) translate(3px, -6px);
  }

  100% {
    -webkit-transform: rotate(0deg) translate(0px);
            transform: rotate(0deg) translate(0px);
  }
}

@keyframes bar3AnimReverse {

  /* 0%{transform: rotate(-45deg) translate(6px, -10px);} */
  0% {
    -webkit-transform: rotate(-45deg) translate(3px, -6px);
            transform: rotate(-45deg) translate(3px, -6px);
  }

  100% {
    -webkit-transform: rotate(0deg) translate(0px);
            transform: rotate(0deg) translate(0px);
  }
}

/* Animation for navigation appearing */
@-webkit-keyframes navAnim {
  0% {
    opacity: 0;
    width: 0%;
  }

  100% {
    opacity: 1;
    width: 100%;
  }
}
@keyframes navAnim {
  0% {
    opacity: 0;
    width: 0%;
  }

  100% {
    opacity: 1;
    width: 100%;
  }
}

@-webkit-keyframes navAnimReverse {
  0% {
    opacity: 1;
    width: 100%;
  }

  100% {
    opacity: 0;
    width: 0%;
  }
}

@keyframes navAnimReverse {
  0% {
    opacity: 1;
    width: 100%;
  }

  100% {
    opacity: 0;
    width: 0%;
  }
}

@media (min-width: 801px) {
  nav {
    opacity: 1 !important;
  }
}

@media (min-width: 768px) {
  .menuH_container .column {
    width: 15%;
  }
}

@media (max-width: 767px) {
  .menuH_container nav.menuopen {
    width: 100%;
    opacity: 1;
    visibility: visible;
    transition: 0.5s all ease-in-out;
  }

  .menuH_container .menuclose {
    width: 0px;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s all ease-in-out;
  }


  .megamenu_list {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }

  .megamenu_list.show {
    transition: max-height 0.5s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
  }

  .megamenu_panel button span {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transition: 0.5s all ease-in-out;
  }

  .megamenu_panel button.active span {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    transition: 0.5s all ease-in-out;
  }
  .hamburger .bar {
    transition: 0.5s all ease-in-out;
  }
  
  .rotate_hamburger .bar:nth-child(1) {
    -webkit-transform: rotate(45deg) translate(3px, 8px);
            transform: rotate(45deg) translate(3px, 8px);
    transition: 0.5s all ease-in-out;
  }
  
  .rotate_hamburger .bar:nth-child(2) {
    display: none;
    transition: 0.5s all ease-in-out;
  }
  
  .rotate_hamburger .bar:nth-child(3) {
    -webkit-transform: rotate(-45deg) translate(3px, -6px);
            transform: rotate(-45deg) translate(3px, -6px);
    transition: 0.5s all ease-in-out;
  }
}



/* ----------- iPhone 5, Portrait and Landscape ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) {
  .megamenu_list {
    visibility: hidden;
    opacity: 0;
    transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
  }

  .megamenu_list.show {
    visibility: visible;
    opacity: 1;
  }

  .megamenu_panel button span {
    transition: 0.2s all ease-in-out;
  }
}


/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .megamenu_list {
    visibility: hidden;
    opacity: 0;
    transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
  }

  .megamenu_list.show {
    visibility: visible;
    opacity: 1;
  }

  .megamenu_panel button span {
    transition: 0.2s all ease-in-out;
  }
}


/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .megamenu_list {
    visibility: hidden;
    opacity: 0;
    transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
  }

  .megamenu_list.show {
    visibility: visible;
    opacity: 1;
  }

  .megamenu_panel button span {
    transition: 0.2s all ease-in-out;
  }
}






