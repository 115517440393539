.flt{
    float: left;
    width: 100%;
}
.thank_main {
    text-align: center;
}
.thankyou_img {
    min-height: calc(100vh - 208px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.thankyou_text{
font-family: 'Mukta Malar',sans-serif;
font-size: 31px;
font-weight: 500;
color: rgba(113, 37, 39, 1);
}
.thankyou_text1{
font-family: 'Mukta Malar',sans-serif;
font-size: 20px;
font-weight: 500;
color: rgba(201, 143, 44, 1);
}