@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;1,100&family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.img_tag_div {
    background-color: #fff;
    float: left;
    width: 100%;
    padding: 20px;
}

.simpleSubmitButton {
    width: 82px;
    background-color: rgb(0 0 0);
    border: none;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    padding: 7px;
    /* margin-left: 150px; */
    float: right;
    /* margin-top: 20px; */
    /* margin-top: 0px; */
    z-index: 999999;
    margin-bottom: 2px;
}
.simpleSubmitButton1 {
    width: 82px;
    background-color: rgb(0 0 0);
    border: none;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    padding: 7px;
    /* margin-left: 150px; */
    float: right;
    /* margin-top: 20px; */
    /* margin-top: 0px; */
    z-index: 999999;
    pointer-events: none;
    opacity: 0.4;
    margin-bottom: -39px;
}
.submitdiv{
  align-items: center;
}
/*style for inputs*/
.simpleInput {
  width: 100%;
  max-width: 60vw;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: 1px solid #000000 !important;
  box-shadow: 1px 1px 4px #ebebeb;
  -moz-box-shadow: 1px 1px 4px #ebebeb;
  -webkit-box-shadow: 1px 1px 4px #ebebeb;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
}
.simpleInput:focus {
  border: 1px solid #21c6e1;
}
/*style for file upload*/
.fileUpload {
  position: relative;
  width: 10vw;
}
/* .inputUpload {
  position: justify;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: 0;
} */
.fileText {
  position: absolute;
  font-weight: bold;
  font-size: 10vw;
  width: 10vw;
  height: 90%;
  color: #000607;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.spanUpload {
    position: absolute;
    height: 10%;
    width: 10vw;
    font-size: 18px;
    color: #ffffff;
    text-align: left;
    vertical-align: middle;
    justify-content: center;
    display: flex;
    flex-direction: column;
    top: 19%;
    margin-left: 16px;
  
}
.spanUpload1 {
    position: absolute;
    height: 10%;
    width: 10vw;
    font-size: 18px;
    color: #dc0a0a;
    text-align: left;
    vertical-align: middle;
    justify-content: center;
    display: flex;
    flex-direction: column;
    top: 14%;
    margin-left: 16px;
  
}
.iii{
  color: #171717;
  background-color: #171717;
  padding: 100px 0px;
}


