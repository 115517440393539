/* @import url('https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Mukta+Mahee:wght@200;300;400;500;600;700;800&family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.flt {
    width: 100%;
    float: left;
}

.main-page {
    background-color: #FFF6E8;
}

.cart-div {
    text-align: center;
}

.blog-div {   
    margin: 3% 0 1% 0;
}

.blog-div .container{
    background-color: #fefefe;
    padding: 1%;
}

.blog-hr hr {
    width: 50px;
    height: 2px;
    color: #712527;
}

.blog-text {
    padding: 28px 0px;
    font-size: 28px;
    font-weight: bold;
    color: #712527;
    line-height: 41px;
}

.blog-date {
    font-family: 'Mukta Mahee', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #c98f2c;
    letter-spacing: 3px;
    text-transform: uppercase;
    padding: 0 5%;
    margin-bottom: 2%;
}

.blog-textdiv {
    padding: 20px 0px;
}

.blogimg {
    position: relative;
}

.blogimg img {
    width: 100%;
}

.blg-brdr {
    border: 1px solid #712527;
    transform: rotate(-90deg);
    position: absolute;
    top: 126px;
    background-color: #712527;
    width: 126px;
}

.blog-txthead {
    font-family: 'Spectral', serif;
    font-size: 20px;
    font-weight: 500;
    color: #712527;
    padding: 4px 0px;
    padding: 0 5%;
    margin-bottom: 2%;
}

.blog-txtcontnt {
    font-family: 'Spectral', serif;
    font-size: 16px;
    font-weight: 400;
    color: #712527;    
    padding: 0 5%;
}

.blog-cnt-sec{
    border-left: 1.5px solid #712527;
    display: flex;
    flex-direction: column;
}

.blog-bttn {
    font-family: Spectral;
    font-weight: 400;
    color: #f4e6d1;
    margin: 3% 0;
    padding: 0 5%;
}

.blog-bttn button {
    background-color: #712527;
    border: none;
    color: #f4e6d1;
    font-size: 14px;
    font-weight: 400;
    line-height: 13px;
    padding: 2% 5%;
    font-family: Spectral;
    letter-spacing: 4px;
}

.blog-sec-content{
    margin: 5% 0;
}

hr {
    border: 1px solid #712527 !important;
}

@media (max-width: 767px) {
    .blog-div {
        margin: 4% 0;
        padding: 0 4%;
    }
    .blog-div .container{
        padding: 3%;
    }
    .blog-textdiv{
        text-align: center;
    }
    .blog-cnt-sec{
        display: block;
        border: none;
    }
}