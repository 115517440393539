.flt {
  float: left;
  width: 100%;
}

.header {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.header a.home-menu,
.header-logo {
  /* float: left; */
  padding: 2%;
  text-decoration: none;
  line-height: 25px;
  text-align: center;
  font-family: MuktaMalarNormal;
  font-size: 14px;
  font-weight: 500;
  color: #712527;
}

/* .header-logo img {
  width: 100%;
} */

.header a.header-logo {
  padding: 0;
}

.header a:hover {
  background-color: transparent;
  color: #c98f2c;
}

.header a.active {
  background-color: transparent;
  color: #c98f2c;
}

.header-cont {
  float: left;
  /* padding: 0 5%; */
  width: 100%;
}

.header-right {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.header-right a {
  text-decoration: none;
}

.logn-bttn {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.sinup-btton {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: left;
}

.menu-main {
  background-color: rgba(244, 230, 209, 1);
  color: rgba(113, 37, 39, 1);
  padding: 14px 0px;
  background-color: #FFE9C2;
  ;
}

.menuH li {
  display: inline-block;
  padding: 0px 12px;
}

.menuH li a {
  text-align: center;
  font-family: MuktaMalarNormal;
  font-size: 14px;
  font-weight: 500;
  color: #c98f2c;
  text-decoration: none;
}

.header-sec {
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
}

.logsign-btton {
  display: flex;
}

.logn-bttn a {
  border: 1px solid rgba(113, 37, 39, 0.6);
  border-radius: 26px;
  padding: 4px 20px;
  font-family: MuktaMalarBold;
  font-size: 10px;
  font-weight: 400;
  color: rgba(113, 37, 39, 1);
  background-color: transparent;
  text-decoration: none;
}

.sinup-btton a {
  border: 1px solid rgba(113, 37, 39, 0.6);
  border-radius: 26px;
  padding: 4px 20px;
  font-family: MuktaMalarBold;
  font-size: 10px;
  font-weight: 400;
  color: rgba(113, 37, 39, 1);
  background-color: #f4e6d1;
  text-decoration: none;
}

.cart-count {
  font-size: 10px;
  background-color: white;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #c98f2c;
}

.cart-sec {
  display: flex;
}

.logn-bttn button.btton-log:hover {
  background-color: #712527;
  border: 1px solid #230809;
  border-radius: 26px;
  color: #ffffff;
}

.sinup-btton button.btton-sign:hover {
  background-color: #712527;
  border: 1px solid #230809;
  border-radius: 26px;
  color: #ffffff;
}

.slder-div {
  background: url("../../../assets/images/bannerimg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bannermaindiv {
  display: flex;
  flex-direction: row;
}

.bannr-maintext {
  font-family: "Carattere", cursive;
  font-size: 43px;
  font-weight: 400;
  color: #712527;
  text-align: left;
  line-height: 63px;
}

.bannr-text {
  font-family: "Butler";
  font-size: 25px;
  font-weight: 300;
  color: #712527;
  line-height: 41px;
}

.cart-div {
  text-align: center;
}

.yakshagana-maindiv {
  background: url("../../../assets/images/yakshagana.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 42px 0px;
}

.yakshagana-div {
  padding: 20px 0px;
}

.yakshagana-text {
  font-family: ButlerNormal;
  font-size: 22px;
  font-weight: 300;
  color: #ffffff;
  text-align: justify;
  padding: 0px 26px;
}

.yakshagana-firsttxt {
  position: absolute;
  top: 97px;
  left: -116px;
  transform: rotate(-90deg);
  text-transform: uppercase;
  font-family: "Marcellus";
  font-size: 15px;
  font-weight: 400;
  color: #f4e8d4;
  text-align: center;
}

.yakshagana-sectxt {
  position: absolute;
  transform: rotate(-90deg);
  top: 59px;
  right: -38px;
  text-transform: uppercase;
  font-family: "Marcellus";
  font-size: 15px;
  font-weight: 400;
  color: #f4e8d4;
  text-align: center;
}

.yakshagana-thirdtxt {
  position: absolute;
  transform: rotate(-90deg);
  top: 52px;
  right: -33px;
  text-transform: uppercase;
  font-family: "Marcellus";
  font-size: 15px;
  font-weight: 400;
  color: #f4e8d4;
  text-align: center;
}

.yakshagana-img {
  position: relative;
  width: 100%;
}

.yakshagana-img img {
  width: 100%;
}

.yakshagana-toptop {
  position: relative;
  padding: 6px 0px;
}

.yakshagana-topbtm {
  position: relative;
  padding: 6px 0px;
}

.yakshagana-righttop {
  position: relative;
  padding: 0px 40px;
}

.yakshagana-righttop img {
  width: 100%;
}

.yakshagana-rightbttm {
  padding: 0px 40px;
}

.yakshagana-rightbttm img {
  width: 100%;
}

.hrdividr {
  height: 4px !important;
  width: 100%;
  color: rgba(201, 143, 44, 1);
}

.yakshagana-sectext {
  font-family: MuktaMalarNormal;
  font-size: 28px;
  font-weight: 400;
  color: #c98f2c;
  text-align: right;
}

.cultural-maindiv {
  background-color: rgb(255, 247, 235);
  padding: 10px 0px;
}

.cultural-text {
  font-family: ButlerBold;
  font-size: 28px;
  font-weight: bold;
  color: #712527;
  text-align: center;
  padding: 2% 0;
}

.cultr-imgright {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cultr-img {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.cultural-img-maindiv .col-sm-3 {
  padding-left: 0.5%;
  padding-right: 0.5%;
}

.cultr-div {
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 6px;
  border-color: #969492;
  border-width: 1px;
  border-radius: 10px;
  border-style: solid;
}

.cultural-img-maindiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 12px 0px 40px 0px;
}

.cultur-text {
  font-size: 13px;
  color: #585756;
  font-family: ButlerNormal;
  text-align: left;
  padding: 8px 0px;
  display: flex;
  width: 100%;
}

.cultural-buttn button {
  border: 1px solid #712527;
  border-radius: 26px;
}

.cultural-buttn {
  text-align: center;
  padding: 28px 0px;
}

.strypurps-main {
  padding: 10px 0px;
}

.stry-purpseimg {
  text-align: center;
}

.stry-purpshead {
  font-family: "Carattere", cursive;
  font-size: 43px;
  font-weight: 400;
  color: #712527;
  line-height: 63px;
}

.stry-purpsetext {
  font-family: ButlerNormal;
  font-size: 20px;
  font-weight: 300;
  color: #712527;
  text-align: justify;
}

.stry-purpsedivdr {
  color: #c98f2c;
  float: right !important;
}

.stry-purpsbht {
  font-family: MuktaMalarNormal;
  font-size: 28px;
  font-weight: 400;
  color: #c98f2c;
  line-height: 28px;
  text-align: right;
}

.strypurps-rightimg img {
  width: 100%;
}

.phtfootgmus-maindiv {
  background: url("../../../assets/images/vistng.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.phtftgmsc-div {
  padding: 12px 0px 42px;
}

.phtftgmus-img img {
  width: 100%;
}

.visiting-main {
  padding: 68px 0;
}

.visiting-img img {
  width: 100%;
}

.visiting-text {
  font-family: ButlerNormal;
  font-size: 20px;
  font-weight: 300;
  color: #ffffff;
  text-align: left;
  padding-left: 4%;
  padding-right: 4%;
}

.visiting-main .row {
  background-color: #c98f2c;
}

.visiting-main .col-sm-5 {
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  display: flex;
  height: 100%;
}

.visiting-main .col-sm-7 {
  padding-left: 0px;
  padding-right: 0px;
}

.visiting-main .container {
  max-width: 100%;
}

.bottom-mainbanner {
  background: url("../../../assets/images/yakshagana.png");
  padding: 62px 0px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bottm-bannertext {
  font-family: ButlerMedium;
  font-size: 28px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 41px;
  margin-bottom: 28px;
}

.botm-bannergallry {
  padding: 10px 0px;
}

.botm-bannergallry ul {
  padding: 0px;
  margin-bottom: 0px;
}

.bottom-mainbanner .container {
  /* max-width: 100%; */
  padding-left: 0px;
  padding-right: 0px;

}

.bottmrow-gallry {
  display: flex;
}

.bottmrow-gallry img {
  width: 100%;
}

.footer-menu li {
  display: inline-block;
  padding: 0px 30px;
}

.footer-menu li a {
  text-align: center;
  text-decoration: none;
  font-family: MuktaMalarNormal;
  font-size: 12px;
  font-weight: 500;
  color: rgba(113, 37, 39, 1);
}

.footer-back {
  font-family: MuktaMalarNormal;
  font-size: 10px;
  font-weight: 400;
  color: #712527;
  letter-spacing: 0px;
  line-height: 12px;
  display: flex;
  justify-content: flex-end;
}

.back-to-top-btn {
  border: none;
  background: none;
  display: flex;
  align-items: center;
}

.bottm-footer {
  font-family: MuktaMalarNormal;
  font-size: 13px;
  font-weight: 400;
  color: #712527;
  line-height: 25px;
}

.footer-logo {
  text-align: center;
}

.footer-main {
  border-top: 1px solid rgb(255 233 194);
  padding: 10px 0px;
  background-color: rgb(255, 247, 235);
}

.footer-menu {
  padding: 0px;
  margin: 0px;
}

.footer-txtbtn {
  display: flex;
  text-align: right;
}

.footer-sec-last {
  justify-content: center;
  display: flex;
  align-items: center;
}

.back-to-top {
  font-family: MuktaMalarNormal;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #712527;
  margin-right: 15px;
}

.footer-content {
  text-align: center;
}

.rrc-bannerimg,
.bannermaindiv {
  display: flex !important;
  justify-content: space-between;
}

.bannermaindiv {
  padding: 10px 10px;
}

.rrc-bannerimg div {
  display: flex;
  align-items: center;
}

.banner-txt {
  padding-left: 24px;
}

.bannr-dividr hr {
  color: rgba(201, 143, 44, 1);
  width: 15%;
  height: 2px !important;
}

.responsive-header {
  display: none;
}

.image-main-div {
  width: 25%;
  display: flex;
  margin: 0px 6px;
}

.cultr-imgone {
  margin-bottom: 8px;

}

.cultr-imgone img,
.cultr-imgtwo img,
.cultr-imgthree img {
  width: 100%;
  height: 100%;
}

.cultr-imgthree {
  margin-left: 8px;
  width: 50%;
}

.all-coctn-div {
  padding: 0px 462px;
  margin: 40px 0px;
}

.all-collctn {
  color: rgba(113, 37, 39, 1);
  border: 1px solid rgba(113, 37, 39, 0.6);
  border-radius: 20px;
  font-family: MuktaMalarMedium;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 2px;
  text-align: center;
  padding: 6px 20px !important;
  font-size: 14px;
  text-transform: uppercase;
  width: auto !important;
  margin: 0% auto;
}

.all-collctn:hover {
  background-color: rgba(113, 37, 39, 0.6);
  color: white;
}

.cultr-imgone img {
  border-radius: 10px;
}

.cultr-imgtwo img {
  border-radius: 10px;
}

.cultr-imgthree img {
  border-radius: 10px;
}

.phtftgmus-img {
  position: relative;
  cursor: pointer;
}

.colc {
  text-align: center !important;
  margin-top: 26px;
}

.phtftgmus-img .view_more {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0px;
  opacity: 0;
  visibility: hidden;
  height: 0px;
  background-color: rgb(194 130 19 / 60%);
  transition: 0.3s all ease-in-out;
}

.phtftgmus-img:hover .view_more {
  width: 100%;
  height: 100%;
  visibility: visible;
  opacity: 1;
  transition: 0.3s all ease-in-out;
}

.phtftgmus-img .view_more span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 4px;
  width: 120px;
  text-align: center;
}

.copyright {
  padding: 25px 0px;
  background-color: #FFE9C2;
}

@media (max-width: 768px) {
  .yakshagana-firsttxt {
    position: absolute;
    top: 95px;
    left: -97px;
    transform: rotate(-90deg);
    text-transform: uppercase;
    font-family: "Marcellus";
    font-size: 15px;
    font-weight: 400;
    color: #f4e8d4;
    text-align: center;
  }

  .yakshagana-sectxt {
    position: absolute;
    transform: rotate(-90deg);
    top: 52px;
    right: -20px;
    text-transform: uppercase;
    font-family: "Marcellus";
    font-size: 12px;
    font-weight: 400;
    color: #f4e8d4;
    text-align: center;
  }

  .yakshagana-thirdtxt {
    position: absolute;
    transform: rotate(-90deg);
    top: 44px;
    right: -18px;
    text-transform: uppercase;
    font-family: "Marcellus";
    font-size: 12px;
    font-weight: 400;
    color: #f4e8d4;
    text-align: center;
  }

  .bottm-bannertext {
    font-size: 20px;
  }

  .visiting-text {
    padding-top: 4%;
    padding-bottom: 4%;
    text-align: center;
  }

  .phtftgmus-img {
    padding: 10px 10px;
  }

  .stry-purpshead {
    line-height: 40px;
    text-align: center;
    padding: 5% 2%;
  }

  .stry-purpsetext {
    text-align: center;
    padding: 2%;
  }

  .stry-purpsbht {
    text-align: center;
  }

  .strypurps-rightimg {
    padding: 2%;
  }

  .bannermaindiv {
    display: block !important;
    padding: 0;
  }

  .bannr-dividr {
    display: flex;
    justify-content: center;
  }

  .bannr-maintext,
  .bannr-text {
    text-align: center;
    /* font-size: 20px; */
  }

  .bannr-text {
    padding-bottom: 10%;
    font-size: 20px;
  }

  .footer-menu {
    padding: 0;
  }

  .bottm-footer {
    text-align: center;
  }

  .cultural-img-maindiv {
    flex-direction: column;
  }

  .cultural-img-maindiv .image-main-div {
    padding: 2% 4%;
  }

  .header {
    display: none !important;
  }

  .responsive-header {
    display: flex;
    padding: 0 3%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(244, 230, 209, 1);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }

  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 14px;
    color: rgba(113, 37, 39, 1);
    font-family: MuktaMalarNormal;
    display: block;
    transition: 0.3s;
  }

  .sidenav a:hover {
    color: #f1f1f1;
  }

  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  .rrc-bannerimg div:first-child img {
    width: 100%;
  }

  .cultr-img {
    justify-content: space-between;
    width: 50%;
  }

  .image-main-div {
    width: 100%;
  }

  .all-coctn-div {
    padding: 0 25%;
  }

  .banner-txt {
    padding: 0px;
    font-size: 20px;
  }
}

@media only screen and (min-device-width: 769px) and (max-device-width: 1200px) {
  .header-cont {
    padding: 0 3%;
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }

  .banner-txt {
    padding: 0px;
    font-size: 20px;
  }

}

@media (max-width: 767px) {
  .rrc-bannerimg {
    justify-content: center;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .banner-txt {
    padding: 0px;
    font-size: 20px;
  }

  .footer-menu li {
    padding: 0px 8px;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .footer-menu li {
    padding: 0px 10px;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .footer-menu li {
    padding: 0px 10px;
  }

  .yakshagana-maindiv .col-sm-4 {
    width: 100%;
  }

  .cultural-maindiv .col-sm-6 {
    width: 100%;
  }

  .visiting-main .col-sm-7,
  .visiting-main .col-sm-5 {
    width: 100%;
  }

  .blog-div .col-sm-6 {
    width: 100%;
  }

  .event-main .col-sm-3 {
    width: 50%;
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .footer-menu li {
    padding: 0px 10px;
  }

  .rrc-bannerimg {
    justify-content: center;
  }

  .yakshagana-maindiv .col-sm-4 {
    width: 100%;
  }

  .cultural-maindiv .col-sm-6 {
    width: 100%;
  }

  .visiting-main .col-sm-7,
  .visiting-main .col-sm-5 {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .all-coctn-div {
    padding: 0px;
  }
}