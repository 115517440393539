.flt {
  float: left;
  width: 100%;
}
.contactus_main {
  background-color: #fff6e8;
  padding-top: 30px;
}
.contact_details {
  font-family: "Mukta Malar";
  font-size: 24px;
  font-weight: 400;
  color: rgba(113, 37, 39, 1);
  text-align: center;
  letter-spacing: 1px;
  padding: 10px 0px;
}
.contact_details span {
  position: relative;
}
.contact_details span::before {
  content: "";
  position: absolute;
  top: 18px;
  left: -72px;
  height: 3px;
  width: 65px;
  background-color: rgba(201, 143, 44, 1);
}
.contact_details span::after {
  content: "";
  position: absolute;
  width: 65px;
  height: 3px;
  top: 18px;
  right: -72px;
  background-color: rgba(201, 143, 44, 1);
}
.contact_content {
  font-family: "Butler";
  font-size: 19px;
  font-weight: 300;
  color: rgba(113, 37, 39, 1);
  text-align: center;
  padding: 30px 0px;
}
.main_content_box {
  font-family: "Butler";
  font-size: 14px;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  min-height: 100px;
  padding: 26px;
  border: 1px solid rgba(201, 143, 44, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 202px;
}
.icon_main {
  /* border: 1px solid rgba(201, 143, 44, 1); */
  margin-bottom: -20px;
}
.icon_main span {
  border: 1px solid rgba(201, 143, 44, 1);
  padding: 5px;
  display: inline-block;
  background-color: #f4e6d1;
}
.main_box {
  text-align: center;
}
.map_box {
  width: 100%;
  background-image: url("../../../assets/images/contact_bg.png");
  background-repeat: no-repeat;
  padding: 35px 80px;
  background-color: rgba(255, 255, 255, 1);
  margin-top: 54px;
  background-size: cover;
}
.google_map {
  width: 600px;
  height: 450px;
  border: 0px;
}
.map_box iframe {
  display: block !important;
  border-radius: 6px;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);
}
@media (min-width: 320px) and (max-width: 479px) {
  .map_box {
    padding: 35px 12px !important;
  }
  .contact_details span::after {
    width: 59px;
    right: -59px;
  }
  .contact_details span::before {
    left: -59px;
    width: 57px;
  }
  .icon_main span {
    margin-top: 14px;
  }
  .main_box {
    margin-bottom: 20px;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .main_box {
    margin-bottom: 20px;
  }
}
@media (min-width: 640px) and (max-width: 767px) {
  .main_content_box {
    min-height: 120px;
    padding: 26px 5px;
    word-break: break-word;
  }
}
